import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const AwardedBids = (props) => {

  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);  // Initialize as an array
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);

  var udata = JSON.parse(user);
  console.log(udata[0]);
  var companyid = udata[0].company_user.id;
  var userid = udata[0].id;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
  };
  
  const data = {};

  console.log('bids/myawarded/' + userid);

  useEffect(() => {
    setLoader(true);
    axiosRequest.get('bids/myawarded/' + userid, data, config)
      .then(function (response) {
        console.log(response);
        
        // Ensure response.data is an array or setData to an empty array if not
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else {
          setData([]);  // Fallback in case response.data is not an array
        }
        
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        if (error.response && error.response.data.message === 'Invalid Token') {
          navigate("/logout");
        }
      });
  }, [userid, navigate]);  // Add missing dependencies to useEffect

  const usrView = (e) => {
    navigate("/bids/view/" + e);
    window.location.pathname = '/bids/view/' + e;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  };

  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th>Title</th>
          <th>Created Date</th>
          <th>Bid Opening Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          users.length > 0 ? users.map(user => (
            <tr key={user.id}>
              <td>{user.cover}</td>
              <td>{formatDate(user.createdAt)}</td>
              <td>{formatDate(user.bid_tender.bid_opening_date)}</td>
              <td>
                <a href="#" onClick={() => usrView(user.id)}>View</a>
              </td>
            </tr>
          )) : (
            !isLoader && <tr><td colSpan="4">No awarded bids found</td></tr>
          )
        }
        {
          isLoader && (
            <tr>
              <td colSpan="4">
                <Loader />
              </td>
            </tr>
          )
        }
      </tbody>
    </table>
  );
};

export default AwardedBids;
