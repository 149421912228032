import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row } from "react-bootstrap";
import Login from "../components/login";
import Sidebar from "../components/users/frontpagesidebar"

function LoginPage() {
  const token = sessionStorage.getItem('token');
  
  document.title = "Login | ConnectIND"; 

  if (token) {
    window.location.pathname = '/dashboard';
  }

  return (
    <div className='main container'>
        <Row>
        <Col md={3} className='home'>
          <Sidebar />
        </Col>
        <Col md={9}>
          <Login />
        </Col>
        </Row>
    </div>
  );
}

export default LoginPage;
