export class LoginHelpers {
    static validate(email, password) {
        let response;

        //case when there is no email or password
        if(!email) {
            return new ValidationResponse(
                false,
                "Email is blank",
                ""
            );
        }
        if(!password) {
            return new ValidationResponse(
                false,
                "",
                "Password is blank"
            );
        }
        //validate email
        if (!email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
            return new ValidationResponse(
                false,
                "email not Valid",
                ""
            );
        }
        //validate password
        
        //if (!password.match(/^[a-zA-Z]{,22}$/)) {
        //    return new ValidationResponse(
        //        false,
        //        "",
        //        "Only Letters and length must be min 8 Chracters and Max 22 Chracters"
        //    );
        //} 

        return new ValidationResponse();
    }
}

class ValidationResponse{
    constructor(isValid = true, emailError, passwordError) {
        this.isValid = isValid;
        this.emailError = emailError ? emailError : "";
        this.passwordError = passwordError ? passwordError : "";
    }
}