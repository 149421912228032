import React from "react";
import { Col, Row } from "react-bootstrap";
import LiveTender from "../components/tenders/livetenders";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../components/users/sidebar";

const LiveTenders = (props) => {

    const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    document.title = "Live Tenders | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
        <strong class="pgheading">Live Tenders</strong>
        <br />
        <Link className="btn btn-primary float-end" to="/tender/post">Post a New Tender</Link>
            <br /><br />
            <LiveTender />
        </Col>
        </Row>
        </div>
    )
}

export default LiveTenders;