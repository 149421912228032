import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';
import { height } from "@fortawesome/free-regular-svg-icons/faAddressBook";

const Tenderslisthome = (props) => {
  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const data = {
  }


  useEffect(() => {
    setLoader(true);
        axiosRequest.get('tenders?start=0&limit=5', data, config)
        .then(function (response){
          if(response.data)
            setData(response.data)
          setLoader(false);
        })
        .catch(function (error) {
        });
    }, []);

  const usrView = (e) => {
    navigate("/tender/details/"+e);
    window.location.pathname = '/tender/details/'+e;
  }
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
    
    return (
              <table className="table borderless hometable">
                  <thead>
                      <tr>
                          <th style={{ width: "40%" }}>Tender Title</th>
                          <th style={{ width: "20%" }}>Reference No</th>
                          <th style={{ width: "20%" }}>Closing Date</th>
                          <th style={{ width: "20%" }}>Bids Opening On</th>
                      </tr>
                  </thead>
                  <tbody>
                      {users && users.map(user =>
                          <tr key={user.id}>
                            <td style={{ width: "40%" }}><a href="#" onClick={usrView.bind(this, user.id)} className="link2">{user.title}</a></td>
                            <td style={{ width: "20%" }}>{user.reference_no}</td>
                            <td style={{ width: "20%" }}>{formatDate(user.bid_submission_closing_date)}</td>
                            <td style={{ width: "20%" }}>{formatDate(user.bid_opening_date)}</td>
                          </tr>
                      )} 
                  </tbody>
                  <thead>
                      <tr>
                          <th style={{ width: "80%" }}>Latest Tenders updates every 15 mins.</th>
                          <th style={{ width: "20%" }}><a href="/latest-tenders/" className="link2">More...</a></th>
                      </tr> 
                  </thead>
              </table>
    );
  }
  export default Tenderslisthome;