import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const Search = (props) => {
  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const [states, setState] = useState([]);
  const [pcategories, setPCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [search, setSearch] = useState("");
  const [stateValue, setStateValue] = useState('');
  const [pcatValue, setPCatValue] = useState('');
  const [catValue, setCatValue] = useState('');
  const [companyValue, setComValue] = useState('');
  const [sortValue, setSortValue] = useState('');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const data = {
  }

const queryParams = new URLSearchParams(window.location.search);
var q ='';
var qstate ='';
var qcat ='';
var qpcat ='';
var qcom ='';
var sort ='1';

if(queryParams.get("q"))
{
  q = queryParams.get("q");
}
if(queryParams.get("state"))
{
  qstate = queryParams.get("state");
}
if(queryParams.get("pcategory"))
{
  qpcat = queryParams.get("pcategory");
}
if(queryParams.get("category"))
{
  qcat = queryParams.get("category");
}
if(queryParams.get("company"))
{
  qcom = queryParams.get("company");
}
if(queryParams.get("sort"))
{
  sort = queryParams.get("sort");
}

  useEffect(() => {
    setLoader(true);
      axiosRequest.get('location/states', data, config)
          .then(function (response){
            setState(response.data)
          })

      axiosRequest.get('company', data, config)
          .then(function (response){
            setCompanies(response.data)
          })

      axiosRequest.get('tenders/productcategories', data, config)
          .then(function (response){
            setPCategories(response.data)
          })
      axiosRequest.get('tenders/categories', data, config)
          .then(function (response){
            setCategories(response.data)
          })

          setStateValue(qstate);
          setPCatValue(qpcat);
          setCatValue(qcat);
          setComValue(qcom);
          setSortValue(sort);
          setSearch(q);

      if(q!='' || qstate!='' || qcom!='' || qcat!='' || sort!='')
      {
        console.log('tenders/search/?q='+q+'&qstate='+qstate+'&cat='+qcat+'&company='+qcom+'&pcat='+qpcat+'&sort='+sort);
        axiosRequest.get('tenders/search/?q='+q+'&qstate='+qstate+'&cat='+qcat+'&company='+qcom+'&pcat='+qpcat+'&sort='+sort, data, config)
        .then(function (response){
          setData(response.data)
          setLoader(false);
        })
        .catch(function (error) {
          if(error.response.data.message=='Invalid Token')
          {
              navigate("/logout");
          }
        });
      }
      else
      {
          axiosRequest.get('tenders', data, config)
        .then(function (response){
          if(response.data)
            setData(response.data)
        })
        .catch(function (error) {
          if(error.response.data.message=='Invalid Token')
          {
              navigate("/logout");
          }
        });
      }
    }, []);

  const usrView = (e) => {
    navigate("/tender/details/"+e);
    window.location.pathname = '/tender/details/'+e;
  }

  const handleChange = (e) => { 
    const { value, checked } = e.target;
    console.log(`${value} is ${checked}`);
  }; 

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const thsyle = {
    fontSize: "14px",
    fontFamily: "tahoma"

  };
  const tdsyle = {
    fontSize: "12px",
    fontFamily: "tahoma"
  };
    
    return (
            <div className="main">
                <div className="searchbox1">
                <Form className="d-flex" action='/search-results'>
              <Row>
                  <h1 className="center">Search for Latest Active Tenders</h1>
                  <Col sm={4} className="filter">
                    <label for="keyword">Keyword</label>
                    <Form.Control
                      type="search"
                      placeholder="Enter Keyword"
                      name="q"
                      value={search} 
                      onChange={e => setSearch(e.target.value)}
                      defaultValue=""
                      className="me-2"
                      aria-label="Search"
                    />
                    </Col>
                    <Col sm={4} className="filter">
                    <label for="organization">Organisation Name:</label>
                    <select className="form-control selectpicker" name="company" onChange={(e) => setComValue(e.target.value)} value={companyValue}>
                    <option value="">- Select Organisation Name -</option>
                    {companies && companies.map(company =>
                      <option value={company.id}>{company.name}</option>
                    )}
                    </select>
                    </Col>
                    <Col sm={4} className="filter">
                    <label for="State">State</label>
                    <select className="form-control selectpicker" name="state" onChange={(e) => setStateValue(e.target.value)} value={stateValue}>
                    <option value="">- Select State -</option>
                    { states && states.map(state =>                      
                      <option value={state.id}> {state.name}</option>
                    )}
                    </select>
                    </Col>
                    <Col sm={4} className="filter">
                    <label for="pcategory">Product Category</label>
                    <select className="form-control selectpicker" name="pcategory" onChange={(e) => setPCatValue(e.target.value)} value={pcatValue}>
                    <option value="">- Select Product Category -</option>
                    {pcategories && pcategories.map(pcategory =>
                      <option value={pcategory.id}>{pcategory.name}</option>
                    )}
                    </select>
                    </Col>
                    <Col sm={4} className="filter">
                    <label for="category">Category</label>
                    <select className="form-control selectpicker" name="category" onChange={(e) => setCatValue(e.target.value)} value={catValue}>
                    <option value="">- Select Category -</option>
                    {categories && categories.map(category =>
                      <option value={category.id}>{category.name}</option>
                    )}
                    </select>
                    </Col>
              <Col sm={4} className="filter">
                    <label for="sort">Sort Option</label>
                    <select className="form-control selectpicker" name="sort" onChange={(e) => setSortValue(e.target.value)} value={sortValue}>
                    <option value="">- Sort By -</option>
                    <option value="1">Published Date</option>
                    <option value="2">Bid Submission End Date</option>
                    <option value="3">Bid Opening Date</option>
                    </select>
                </Col>
                <Col sm={12} className="filter center">
                      <Button type="submit" variant="primary">
                        Search
                      </Button>
                      &nbsp;&nbsp;
                      <a href="/" className="btn btn-primary" variant="outline-primary">
                        Cancel
                      </a>
                  </Col>
              </Row>
              </Form>
            </div>
            <Row>
            <Col sm={12}>
            { 
              isLoader &&
                <Loader></Loader>
            }
            </Col>
            </Row>
            <Row>
            <Col sm={12}>
              <table className="table table-striped table-bordered">
                  <thead style={thsyle}>
                      <tr>
                          <th>e-Published Date</th>
                          <th>Bid Submission Closing Date</th>
                          <th>Bids Opening On</th>
                          <th colSpan={2}>Title/Ref.No./Tender Id</th>
                          <th>Organization Name</th>
                          <th>Corrigendum</th>
                      </tr>
                  </thead>
                  <tbody style={tdsyle}>
                      {users && users.map(user =>
                          <tr key={user.id}>
                            <td>{formatDate(user.published_date)}</td>
                            <td>{formatDate(user.bid_submission_closing_date)}</td>
                            <td>{formatDate(user.bid_opening_date)}</td>
                            <td colSpan={2}><a href="#" onClick={usrView.bind(this, user.id)}>{user.title}</a> / </td>
                            <td>{user.tender_company.name}</td>
                            <td>--</td>
                          </tr>
                      )}
                  </tbody>
              </table>
            </Col> 
            </Row>
        </div>
    );
  }
  export default Search;