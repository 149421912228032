import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Document from "../components/users/documents";
import Sidebar from "../components/users/sidebar";

function Homepage() {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  document.title = "Company Documents"; 

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    
  return(
      <div className='main container'>
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
            <Document />
        </Col>
        </Row>
    </div>
  );
}

export default Homepage;