import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';  // Assuming you're using React Router
import { axiosRequest } from "../../api";
import FileUpload from '../App/FileUpload'; // Import your FileUpload component

const TCQueryDetails = () => {
    const { id } = useParams(); // Get shortfall request ID from the URL
    const [shortfall, setShortfall] = useState(null);
    const [replies, setReplies] = useState([]); // State to hold replies
    const user = sessionStorage.getItem('user');
    const [reply, setReply] = useState('');
    const [filePaths, setFilePaths] = useState({
        document: null,
    }); // State to hold file paths for uploads

    const token = sessionStorage.getItem('token');
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    var userid = 0;  
    if (user) {
        var udata = JSON.parse(user);
        userid = udata[0].id;
    }

    useEffect(() => {
        // Fetch the shortfall request details based on the ID
        axiosRequest.get(`/support/tcquery/${id}`, config)
            .then(response => {
                setShortfall(response.data);
            })
            .catch(error => {
                console.error('Error fetching technp commercial query details:', error);
            });
    }, [id]);

    useEffect(() => {
        // Fetch replies from the API
        const fetchReplies = async () => {
            try {
                const response = await axiosRequest.get(`/support/tcqueryreplies/${id}`, config);
                setReplies(response.data);
            } catch (error) {
                console.error('Error fetching replies:', error);
            }
        };

        fetchReplies();
    }, [id]);

    const handleReplyChange = (e) => {
        setReply(e.target.value);
    };

    const handleUploadComplete = (fileKey, result) => {
        if (result) {
            setFilePaths(prevPaths => ({
                ...prevPaths,
                [fileKey]: result,
            }));
        } else {
            console.log(`Upload for ${fileKey} failed.`);
        }
    };

    const handleReplySubmit = async () => {
        // Form validation
        if (!reply.trim()) {
            alert('Reply cannot be empty. Please enter your reply.');
            return;
        }

        const formData = new FormData();
        formData.append('reply', reply);
        if (filePaths.document) {
            formData.append('file', filePaths.document);
        }

        try {
            const response = await axiosRequest.post(`/support/addtcqreply/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                    userid: `${userid}`
                }
            });
            console.log('Reply submitted successfully:', response.data);
            alert('Reply submitted successfully');

            // Reset the reply input and file paths
            setReply('');
            setFilePaths({ document: null });

            // Reload replies
            const fetchReplies = async () => {
                try {
                    const response = await axiosRequest.get(`/support/tcqueryreplies/${id}`, config);
                    setReplies(response.data);
                } catch (error) {
                    console.error('Error fetching replies:', error);
                }
            };

            fetchReplies();
        } catch (error) {
            console.error('Error submitting reply:', error);
            alert('Failed to submit reply');
        }
    };

    if (!shortfall) {
        return <p>Loading...</p>;
    }

    return (
        <div>
            <strong className="pgheading">Techno Commercial Query Details</strong>
            <br /><br />
            <div className="request-details">
                <table className='table'>
                    <tbody>
                        <tr>
                            <td><strong>Tender Reference No:</strong> {shortfall.tender?.reference_no}</td>
                            <td><strong>Requested On:</strong> {new Date(shortfall.requested_on).toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}><strong>Status:</strong> {shortfall.is_resolved === 1 ? "Closed" : "Open"}</td>
                        </tr>
                        <tr>
                            <td colSpan={2}>{shortfall.request_Description}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="replies">
                {replies.map(reply => (
                    <div key={reply.id} className={reply.replied_by_user === userid ? 'user-reply reply' : 'company-reply reply'}>
                        <div className="reply-header">
                            {reply.company}
                            <span className="reply-time"><small>{new Date(reply.replied_on).toLocaleString()}</small></span>
                        </div>
                        <p>{reply.reply_text}</p>
                        {reply.reply_document && (
                            <a href={reply.reply_document} target="_blank" rel="noopener noreferrer" className="attachment-link">
                                View Attachment
                            </a>
                        )}
                    </div>
                ))}
            </div>

            {/* Display reply form only if the TCQ request is open */}
            {shortfall.is_resolved === 0 && (
                <div className="reply-form" id="replyform">
                    <strong className="pgheading">Reply to Techno Commercial Query</strong>
                    <div className="form-group">
                        <label>Reply</label>
                        <textarea
                            value={reply}
                            onChange={handleReplyChange}
                            rows="4"
                            cols="50"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Attach File</label>
                        <FileUpload 
                            s3Folder="tcqueries-docs" // Change this to your desired S3 folder
                            onUploadComplete={(result) => handleUploadComplete('document', result)} 
                        />
                    </div>
                    <button 
                        type="button" 
                        className="btn btn-primary btn-sm" 
                        onClick={handleReplySubmit}
                    >
                        Submit Reply
                    </button>
                </div>
            )}
        </div>
    );
};

export default TCQueryDetails;
