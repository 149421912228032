import React from "react";
import { Col, Row } from "react-bootstrap";
import EditTender from "../components/tenders/edittender";
import { useNavigate, Link } from "react-router-dom";
import Sidebar from "../components/users/sidebar";

const EditTenderForm = (props) => {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
  
    if(!token) {
      navigate("/login");
      window.location.pathname = '/login';
    }
    
    document.title = "Edit Tender | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
        <strong class="pgheading">Update Tender</strong>
        <br />
        <br />
            <Link to="/mytenders" className="btn btn-primary float-end">Back</Link>
            <EditTender />
        </Col>
        </Row>
        </div>
    )
}

export default EditTenderForm;