import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row, Alert } from "react-bootstrap";
import { axiosRequest } from "../../api";
import FileUpload from '../App/FileUpload';
import Loader from '../App/loader';

const EditBid = () => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [data, setData] = useState({});
  const [isLoader, setLoader] = useState(false);
  const [isError, setError] = useState(false);
  const [err, setERR] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [filePaths, setFilePaths] = useState({
    technical: null,
    financial: null,
  });
  const [showUploader, setShowUploader] = useState({
    technical: false,
    financial: false,
  });
  const [contactdetails, setContactdetails] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
  });

  const userid = user ? JSON.parse(user)[0].id : 0;

  const { id } = useParams();
  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactdetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleUploadComplete = (fileKey, result) => {
    if (result) {
      setFilePaths(prevPaths => ({
        ...prevPaths,
        [fileKey]: result,
      }));
      setData(prevData => ({
        ...prevData,
        [`${fileKey}_doc`]: result,
      }));
      setShowUploader(prevShow => ({
        ...prevShow,
        [fileKey]: false,
      }));
    } else {
      setERR(`Upload for ${fileKey} failed.`);
      setError(true);
    }
  };

  useEffect(() => {
    setLoader(true);
    if (user) {
      axiosRequest.get('/bids/detail/' + id, {}, config)
        .then(function (response) {
          setData(response.data);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoader(false);
        });
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      setContactdetails({
        name: data.name || '',
        phone: data.phone || '',
        email: data.email || '',
        address: data.address || '',
      });
    }
  }, [data]);

  const handleSubmit = (event, isPublish) => {
    setLoader(true);
    setError(false);
    setSuccess(false);
    event.preventDefault();

    const formData = new FormData();
    if (filePaths.technical) {
      formData.append('technical', filePaths.technical);
    }
    else
    {
        formData.append('technical', data.technical_doc);      
    }
    if (filePaths.financial) {
      formData.append('financial', filePaths.financial);
    }
    else
    {
        formData.append('financial', data.financial_doc);      
    }
    formData.append('name', contactdetails.name);
    formData.append('email', contactdetails.email);
    formData.append('phone', contactdetails.phone);
    formData.append('address', contactdetails.address);
    formData.append('status', isPublish ? '2' : '1');
    
    axiosRequest.post('/bids/update/' + id, formData, config)
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.msg === 'Success') {
            setERR('Your bid has been updated successfully.');
            setSuccess(true);
            if (isPublish) {
              navigate('/mybids');
            }
          } else {
            setERR("Error in updating your bid.");
            setError(true);
          }
        } else {
          setERR("Error in updating your bid.");
          setError(true);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error)
        setERR(error.response.data.msg);
        setError(true);
        setLoader(false);
      });
  };

  document.title = "Edit Bid | ConnectIND";
  if (!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }

  return (
    <div>
      <h3 className="theading">Edit Bid</h3>
        <Row>
          <Col>
            <h4>Contact Details</h4>
            <Form.Group>
              <Form.Label>Contact Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={contactdetails.name}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={contactdetails.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={contactdetails.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                rows="3"
                name="address"
                value={contactdetails.address}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <h4>Documents</h4>
            <Form.Group>
              <Form.Label>Technical Proposal</Form.Label>
              {data.technical_doc && (
                <div>
                  <a href={data.technical_doc} target="_blank" rel="noopener noreferrer">View Current Technical Proposal</a>&nbsp;
                  <Button variant="danger" className="btn-sm" onClick={() => setShowUploader(prevShow => ({ ...prevShow, technical: true }))}>Replace</Button>
                </div>
              )}
              {showUploader.technical && (
                <FileUpload
                  s3Folder="proposals"
                  onUploadComplete={(result) => handleUploadComplete('technical', result)}
                />
              )}
            </Form.Group>
            <br /><br />
            <Form.Group>
              <Form.Label>Financial Proposal</Form.Label>
              {data.financial_doc && (
                <div>
                  <a href={data.financial_doc} target="_blank" rel="noopener noreferrer">View Current Financial Proposal</a>&nbsp;
                  <Button className="btn-sm" variant="danger" onClick={() => setShowUploader(prevShow => ({ ...prevShow, financial: true }))}>Replace</Button>
                </div>
              )}
              {showUploader.financial && (
                <FileUpload
                  s3Folder="proposals"
                  onUploadComplete={(result) => handleUploadComplete('financial', result)}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Button variant="secondary" onClick={(e) => handleSubmit(e, false)}>Save Draft</Button>
            &nbsp;
            <Button variant="primary" onClick={(e) => handleSubmit(e, true)}>Publish</Button>
          </Col>
        </Row>
      {isLoader && <Loader />}
      {isSuccess && <Alert variant="success">{err}</Alert>}
      {isError && <Alert variant="danger">{err}</Alert>}
    </div>
  );
};

export default EditBid;
