import React from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

const About = () => {
    return (
      <div className='main'>
       <div class='container'>
          <h1>About US</h1>
            <p>ConnectIND is your leading edge partner in finding the right tenders. We understand your needs; know the opportunities that exist to fulfil them, and help leverage these opportunities in the shortest possible time.</p>
            <p>Our team has some of the industry's most valued experts who understand the pain points of clients and have put together ConnectIND to give you a renewed perspective on Tendering.We have achieved this commendable presence with results across our extensive network.</p>
            <p>To do this, we have mastered the smart application of knowledge, research, and insights and in leading unmatched expertise to our clients' bid requirements with dedicated professionals who understand their needs.We are in the business of uncovering opportunities, opportunities that may be realized with great value.</p>
            <p></p>
       </div>
      </div>
    );
}

export default About;