import React from "react";
import { useNavigate } from "react-router-dom";
import TenderCategories from "../components/tenders/tenderCategories";
import { Col, Row } from "react-bootstrap";
import AdminSidebar from "../components/users/adminsidebar";

function TenderCategoriesPage() {

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }

  document.title = "Tender Categories | ConnectIND"; 
    
  return(
    
    <div className='main container'>
    <Row>
    <Col md={3}>
      <AdminSidebar />
    </Col>
    <Col md={9}>
    <TenderCategories />
    </Col>
    </Row>
</div>
  );
}

export default TenderCategoriesPage;