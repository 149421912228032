import React from "react";
import { Col, Row } from "react-bootstrap";
import Tendersidebar from "../components/users/tendersidebar";
import TendersbyCDay from "../components/tenders/tendersclosingbyday";

const TendersbyDay = (props) => {
    return(
        <div className="main container">
        <Row>
        <Col md={12}>
            <TendersbyCDay />
        </Col>
        </Row>
    </div>
    )
}

export default TendersbyDay;