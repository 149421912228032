import React from "react";
import EditCompany from "../components/users/editcomp";
import { useNavigate } from "react-router-dom";

function EditCompanyPage() {

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    
  return(
    <EditCompany />
  );
}

export default EditCompanyPage;