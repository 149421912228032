import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { axiosRequest } from "../../api";
import Alert from 'react-bootstrap/Alert';
import { LoginHelpers } from "../../classes/helpers/loginHelpers";
import Loader from '../App/loader';

const Login = (props) => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [userType, setUserType] = useState('bidder');
    const [passwordError, setpasswordError] = useState("");
    const [emailError, setemailError] = useState("");
    const [formMessage, setformMessage] = useState("");
    const [isLoader, setLoader] = useState(false);
    const [isError, setError] = useState(false);
    const [otp, setOTP] = useState("");
    const [otpError, setOTPError] = useState("");
    const [showOTPInput, setShowOTPInput] = useState(false); // Flag to show OTP input field
    const [resendDisabled, setResendDisabled] = useState(false); // Disable resend button initially
    const [timer, setTimer] = useState(60); // Timer for OTP resend countdown
    const [otpphone, setotpphone] = useState("");
    const [resending, setResending] = useState(false); 
    const [loginResponse, setLoginResponse] = useState(null); 

    const { validate } = LoginHelpers;

    const loginSubmit = async (e) => {
        e.preventDefault();

        let validationResult = validate(email, password);
        setemailError(validationResult.emailError);
        setpasswordError(validationResult.passwordError);
    
        if (!validationResult.isValid)
            return;

        setLoader(true);    
        axiosRequest.post('/auth/login', {
            email: email,
            password: password,
            })
            .then(response => {
                setformMessage(response.data.msg);
                if (response.data.msg === 'Logged in!') {
                    
                    const userPhone = '+91' + response.data.user[0].phone; // Assuming phone number comes from API response
                    setotpphone(userPhone);
                    
                    setShowOTPInput(true);
                    setLoginResponse(response);
                }
                setLoader(false);
            })
            .catch(function (error) {
                console.log("=="+error);
                if (error.response) {
                    setformMessage(error.response.data.msg);
                    setError(true);
                } else {
                    setformMessage("Error in connecting to server. Please try again after sometime.");
                    setError(true);
                }
                setLoader(false);
            });        
    };

    useEffect(() => {
        if (otpphone !== '' && showOTPInput) {
            sendOTP();
        }
    }, [otpphone, showOTPInput]);

    useEffect(() => {
        let interval;
        if (showOTPInput && timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [timer, showOTPInput]);

    const sendOTP = async () => {
        console.log('sending OTP to ' + otpphone);
        try {
            const response = await axiosRequest.post('/otp/send', {
                phoneNumber: otpphone,
            });
            console.log("OTP Sent successfully:", response.data);
        } catch (error) {
            console.error("Error sending OTP:", error);
            // Handle error in sending OTP
        }
    };

    const handleResendOTP = async (e) => {
        e.preventDefault();
        if (resending) return;

        try {
            setResending(true); // Set state to indicate resending
            setResendDisabled(true); // Disable resend button
            setTimer(60); // Reset timer when resend is initiated
            const response = await axiosRequest.post('/otp/send', {
                phoneNumber: otpphone,
            });

            console.log("OTP Resent successfully:", response.data);
        } catch (error) {
            console.error("Error resending OTP:", error);
        } finally {
            setResending(false); // Reset resending state
        }
    };

    const handleOTPVerification = async (e) => {
        e.preventDefault();
        setLoader(true); 
        // Implement OTP verification functionality
        try {
            const response = await axiosRequest.post('/otp/verify', {
                phoneNumber: otpphone,
                otp: otp,
            });
            console.log(response.data);
            if (response.data.success) {
                console.log("OTP Verified successfully");

                sessionStorage.setItem('token', loginResponse.data.token);
                sessionStorage.setItem('role', loginResponse.data.user[0].role);
                sessionStorage.setItem('user', JSON.stringify(loginResponse.data.user));
                sessionStorage.setItem('type', userType);

                setLoader(false); 
                // Redirect user to appropriate dashboard based on role
                const role = sessionStorage.getItem('role');
                if (role === 'admin') {
                    navigate("/dashboard");
                    window.location.pathname = '/dashboard';
                } else if (role === 'user') {
                    navigate("/homepage");
                    window.location.pathname = '/homepage';
                }
            } else {
                setOTPError("Invalid OTP. Please enter correct OTP.");
            }
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setOTPError("Error verifying OTP. Please try again.");
        } finally {
            setLoader(false);
        }
    };

    return (
        <div>
            <strong class="pgheading">User Login</strong>
                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-lg-8 col-xl-5 order-2 order-lg-1">
                                        {showOTPInput ? (
                                            <form className="mx-1 mx-md-4" onSubmit={handleOTPVerification}>
                                                {isError && <Alert variant="danger">{formMessage}</Alert>}
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <div className="form-outline flex-fill mb-0">
                                                        <label className="form-label" htmlFor="otp">Enter OTP</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            id="otp"
                                                            name="otp"
                                                            placeholder="Enter OTP received on your phone"
                                                            value={otp}
                                                            onChange={(event) => setOTP(event.target.value)}
                                                        />
                                                        <small id="otpHelp" className="text-danger form-text">
                                                            {otpError}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                    <button type="submit" className="btn btn-primary btn-lg">Verify OTP</button>
                                                </div>
                                                <div className="text-center mb-3">
                                                    {timer > 0 ? (
                                                        <p>Resend OTP in {timer} seconds</p>
                                                    ) : (
                                                        <Link onClick={handleResendOTP} className="text-decoration-none">Resend OTP</Link>
                                                    )}
                                                </div>
                                            </form>
                                        ) : (
                                            <form className="mx-1 mx-md-4" onSubmit={loginSubmit}>
                                                {isError && <Alert variant="danger">{formMessage}</Alert>}
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <div className="form-outline flex-fill mb-0">
                                                        <label className="form-label" htmlFor="email">Your Email</label>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            id="email"
                                                            name="email"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter email address"
                                                            value={email}
                                                            onChange={(event) => setEmail(event.target.value)}
                                                        />
                                                        <small id="emailHelp" className="text-danger form-text">
                                                            {emailError}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-row align-items-center mb-4">
                                                    <div className="form-outline flex-fill mb-0">
                                                        <label className="form-label" htmlFor="password">Password</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            id="password"
                                                            name="password"
                                                            placeholder="Enter password"
                                                            value={password}
                                                            onChange={(event) => setPassword(event.target.value)}
                                                        />
                                                        <small id="passwordHelp" className="text-danger form-text">
                                                            {passwordError}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                    <Link to="/" className="btn btn-primary">Cancel</Link> 
                                                    &nbsp;&nbsp;
                                                    <button type="submit" className="btn btn-primary">Login</button>
                                                </div>
                                                <div className="text-center">
                                                    <p className="text-center text-muted mt-5 mb-0">Don't have an account? <Link to="/signup">Sign Up</Link></p>
                                                    <p className="mt-2"><Link to="/passwordreset" className="text-decoration-none">Forget Password?</Link></p>
                                                </div>
                                            </form>
                                        )}
                                    </div>
                                </div>
                                {isLoader && <Loader />}
        </div>
    );
};

export default Login;