import React from "react";
import TenderType from "../components/tenders/tenderType";
import { Col, Row } from "react-bootstrap";
import AdminSidebar from "../components/users/adminsidebar";


function TenderTypePage() {

  document.title = "Tender Categories | ConnectIND"; 
    
  return(
    <div className='main container'>
    <Row>
    <Col md={3}>
      <AdminSidebar />
    </Col>
    <Col md={9}>
    <TenderType />
    </Col>
    </Row>
</div>
  );
}

export default TenderTypePage;