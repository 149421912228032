import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import Loader from '../App/loader';
import { axiosRequest } from "../../api";
import AWS from 'aws-sdk'; 
import S3 from 'aws-sdk/clients/s3';
import FileUpload from '../App/FileUpload';

const Document = (props) => {
  const token = sessionStorage.getItem('token');
  const role = sessionStorage.getItem('role');
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [isLoader, setLoader] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [err, setERR] = useState("");

  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false)

  var udata = JSON.parse(user);
  var id = udata[0].id;

  const isEmpty = (string) => {
    return string === null || string === undefined || string.trim() === "";
  };

  const [filePaths, setFilePaths] = useState({
    coi: null,
    gst: null,
    pan: null,
  });
  const [companyDocs, setCompanyDocs] = useState(null);
  
  const handleUploadComplete = (fileKey, result) => {
    if (result) {
      setFilePaths(prevPaths => ({
        ...prevPaths,
        [fileKey]: result,
      }));
    } else {
      console.log(`Upload for ${fileKey} failed.`);
    }
  };

  var udata = JSON.parse(user);
  
  var {id} = useParams();
  if(!id)
  {
    id = udata[0].id;
  }
  if(role==='user')
  {
    id = udata[0].id;
  }
  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${id}` }
};

var companyid = udata[0].company_user.id;

const userdata = {id}

useEffect(() => {
  setLoader(true);
  axiosRequest.get(`company/getdocuments/${companyid}`, config)
    .then(response => {
      setCompanyDocs(response.data);
      setLoader(false);
    })
    .catch(error => {
      console.log(error);
    });
}, [companyid]);

  
const uploadFile = async (e) => {
    e.preventDefault();
    setLoader(true);
    var err = 0;

if(!isEmpty(filePaths.coi))
  {
    let data = new FormData();
    data.append('coi', filePaths.coi)
    axiosRequest.post("company/uploaddocuments/coi/"+companyid, data, config)
    .then(function (response){
      setLoader(false);
       if(response.status==200)
        {
            if (response.data.msg == 'Success') {
              setERR('Changes to COI file been made successfully.');
              setSuccess(err);
            }
            else
            {
              setERR("Error in uploading documents.");
              setError(true);
            }
        }
        else
        {
          setERR("Error in uploading documents.");
          setError(true);
        }
      })
      .catch(function (error) {
          setERR(error);
          setError(true);
      });
  }
  
    if(!isEmpty(filePaths.pan))
    {
      let data = new FormData();
      data.append('pan', filePaths.pan)
      axiosRequest.post(
        "company/uploaddocuments/pan/"+companyid, data, config)
        .then(function (response){
        setLoader(false);
         if(response.status==200)
          {
              if (response.data.msg == 'Success') {
                setERR('Changes to COI file been made successfully.');
                setSuccess(err);
              }
              else
              {
                setERR("Error in uploading documents.");
                setError(true);
              }
          }
          else
          {
            setERR("Error in uploading documents.");
            setError(true);
          }
        })
        .catch(function (error) {
            setERR(error);
            setError(true);
        });
    }

    if(!isEmpty(filePaths.gst))
      {
        let data = new FormData();
        data.append('gst', filePaths.gst)
        axiosRequest.post("company/uploaddocuments/gst/"+companyid, data, config)
      .then(function (response){
          setLoader(false);
           if(response.status==200)
            {
                if (response.data.msg == 'Success') {
                  setERR('Changes to COI file been made successfully.');
                  setSuccess(err);
                }
                else
                {
                  setERR("Error in uploading documents.");
                  setError(true);
                }
            }
            else
            {
              setERR("Error in uploading documents.");
              setError(true);
            }
          })
          .catch(function (error) {
              setERR(error);
              setError(true);
          });
      }

  };

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    
    return (
                <div>
                <strong class="pgheading">Documents</strong>
                <hr />
                <br />
                {companyDocs && (
                <div>
                  <strong class="pgheading">Existing Documents</strong>
                  <ul>
                    {companyDocs.coi_photo && (
                      <li>
                        <a href={companyDocs.coi_photo} target="_blank" rel="noopener noreferrer">Certificate of Incorporation</a>
                      </li>
                    )}
                    {companyDocs.pan_photo && (
                      <li>
                        <a href={companyDocs.pan_photo} target="_blank" rel="noopener noreferrer">PAN</a>
                      </li>
                    )}
                    {companyDocs.gst_photo && (
                      <li>
                        <a href={companyDocs.gst_photo} target="_blank" rel="noopener noreferrer">GST Certificate</a>
                      </li>
                    )}
                  </ul>
                </div>
              )}
              <hr />
              <strong class="pgheading">Upload</strong>
                <table className="profiletable">
                <tbody>
                    <tr>
                      <td>Certificate of Incorporation</td>
                      <td>
                      <FileUpload 
                        s3Folder="company-docs" 
                        onUploadComplete={(result) => handleUploadComplete('coi', result)} 
                      />
                      </td> 
                      
                    </tr>
                    <tr>
                      <td>PAN</td>
                      <td>
                      <FileUpload 
                        s3Folder="company-docs" 
                        onUploadComplete={(result) => handleUploadComplete('pan', result)} 
                      />
                      </td>
                    </tr>
                    <tr>
                      <td>GST Certificate</td>
                      <td>
                      <FileUpload 
                        s3Folder="company-docs" 
                        onUploadComplete={(result) => handleUploadComplete('gst', result)} 
                      />
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td><button type="submit" className="btn btn-primary" onClick={uploadFile}>Save</button></td>
                    </tr>
                    
                    <tr><td colSpan={2}>
                    { 
                        isLoader &&
                             <Loader></Loader>
                    }                   
                    </td></tr>
                     {
                        isSuccess &&
                        <tr><td colSpan={2}>
                             <Alert variant="success">{err}</Alert>
                        </td></tr>
                     }
                     {
                        isError &&
                        <tr><td colSpan={2}>
                            <Alert variant="danger">{err}</Alert>
                        </td></tr>
                     }
                      
                </tbody>
                </table>
            </div>
    );
  }
  export default Document;