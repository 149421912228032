import React from "react";
import { Col, Row } from "react-bootstrap";
import Sidebar from "../components/users/frontpagesidebar"
import "bootstrap/dist/css/bootstrap.min.css";
import PasswordReset from "../components/login/password";

function PResetPage() {
  const token = sessionStorage.getItem('token');
  
  document.title = "Login | ConnectIND"; 

  if (token) {
    window.location.pathname = '/dashboard';
  }

  return (
    <div className='main container'>
        <Row>
        <Col md={3} className='home'>
          <Sidebar />
        </Col>
        <Col md={9}>
          <PasswordReset />
        </Col>
        </Row>
    </div>
  );
}

export default PResetPage;
