import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const Livetenders = (props) => {
  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);

  var udata = JSON.parse(user);
  var userid = udata[0].id;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
};
const data = {
}

  useEffect(() => {
    setLoader(true);
    axiosRequest.get('tenders/active/user/'+userid, data, config)
      .then(function (response){
        setData(response.data)
        setLoader(false);
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, []);

  const tenderSearch = (event) => {
    setLoader(true);
    if(event.target.value!='')
    {
        axiosRequest.get('tenders/user/'+userid+'?status=2&q='+event.target.value, data, config)
          .then(function (response){
            setData(response.data)
            setLoader(false);
          })
          .catch(function (error) {
            if(error.response.data.message=='Invalid Token')
            {
                navigate("/logout");
            }
          });
    }
    else
    {
        axiosRequest.get('tenders/active/user/'+userid, data, config)
          .then(function (response){
            setData(response.data)
            setLoader(false);
          })
          .catch(function (error) {
            if(error.response.data.message=='Invalid Token')
            {
                navigate("/logout");
            }
          });
    }

  }

  const usrView = (e) => {
    navigate("/tender/details/"+e);
    //window.location.pathname = '/tender/details/'+e;
  }
  const usrEdt = (e) => {
    navigate("/tender/edit/"+e);
    //window.location.pathname = '/tender/details/'+e;
  }
  const bidsView = (e) => {
    navigate("/tenders/bids/"+e);
    //window.location.pathname = '/tenders/bids/'+e;
  }

  const docsView = (e) => {
    navigate("/tenders/documents/"+e);
    //window.location.pathname = '/tenders/documents/'+e;
  }

  const publishTender = (id) => {
    if (window.confirm("Are you sure you want to publish this tender?")) {
      // Add the logic to publish the tender
      console.log("Publish tender:", id);
    }
  }

  const cancelTender = (id) => {
    if (window.confirm("Are you sure you want to cancel this tender?")) {
      // Add the logic to cancel the tender
      console.log("Cancel tender:", id);
    }
  }

  const formatDate = (dateString) => {
    if (!dateString) {
      return ''; // Return blank if dateString is null, undefined, or empty
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const CorView = (e) => {
    navigate("/tenders/corrigendum/" + e);
    window.location.pathname = '/tenders/corrigendum/' + e;
  }
    return (
      <table className="table table-striped table-bordered">
      <thead>
          <tr>
              <th>id</th>
              <th>title</th>
              <th>Pubished Date</th>
              <th>Bid Opening Date</th>
              <th>Action</th>
          </tr>
      </thead>
      <tbody>
              <tr>
                  <td></td>
                  <td><input type="text" onChange={tenderSearch} /></td>
                  <td></td>
                  <td></td>
                  <td></td>
              </tr>
              {!isLoader && users.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center">No Live Tender Found. Check <a href="/mytenders">My Tenders</a> for Drafts.</td>
                </tr>
              )}
          {users && users.map(user =>
              <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>
                    <table>
                      <tr><td colSpan={4}>{user.title}</td></tr>
                      <tr>
                        <td><a href="#" onClick={usrEdt.bind(this, user.id)}>Edit</a>&nbsp;&nbsp;<a href="#" onClick={usrView.bind(this, user.id)}>View</a></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><a href="#" onClick={CorView.bind(this, user.id)}>Corrigendum</a></td>
                        <td><a href="#" onClick={bidsView.bind(this, user.id)}>See All Bids</a></td>
                      </tr>
                    </table>
                    </td>
                    <td>{formatDate(user.published_date)}</td>
                    <td>{formatDate(user.bid_opening_date)}</td>
                  <td><a href="#" onClick={cancelTender.bind(this, user.id)}>Cancel</a></td>
              </tr>
          )}
          { 
              isLoader &&
                   <Loader></Loader>
          }
      </tbody>
  </table>
    );
  }
  export default Livetenders;