import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';
import { Alert } from "react-bootstrap";

const EditCompany = (props) => {
  const token = sessionStorage.getItem('token');    
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [data, setUser] = useState([]);
  const [formMessage, setformMessage] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [states, setState] = useState([]);
  const [cities, setCities] = useState([]);
  const [stateValue, setStateValue] = useState('');
  const [cityValue, setCityValue] = useState('');
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 51 }, (_, i) => currentYear - i);  // Array of years from current year to 50 years past

  var udata = JSON.parse(user);
  var id = udata[0].id;
  
  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${id}` }
};

const userdata = {id}

useEffect(() => {
    setLoader(true);
  if(id)
  {
    axiosRequest.get('/company/user/'+id, userdata, config)
      .then(function (response){
        setLoader(false);
        setUser(response.data);
        setStateValue(response.data.state);

        axiosRequest.get('location/states', data, config)
          .then(function (response){
            setState(response.data)
          })

          if(response.data.state!='')
            {
                axiosRequest.get('location/states/cities/'+response.data.state, data, config)
              .then(function (response){
                console.log(response.data)
                setCities(response.data)
              })
            }
            setCityValue(response.data.city);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}, [])

  const formSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    console.log(data);
    axiosRequest.post('/company/update/'+data.id, data, config)
      .then(function (response){
        setLoader(false);
        if(response.status==200)
        {
            if (response.data.msg == 'Success') {
                setSuccess(true);
                setformMessage('Changes have been made successfully.');
            }
            else
            {
                setError(true);
                setformMessage("Error in updating the form data.")
            }
        }
        else
        {
            setError(true);
            setformMessage("You cannot edit this company.")
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const loadCities = (e) => {
    setUser({...data, state: e})
    axiosRequest.get('location/states/cities/'+e, data, config)
          .then(function (response){
            console.log(response.data)
            setCities(response.data)
          })
      }

  document.title ="Edit Company Profile | ConnectIND"; 
  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    
    return (
            <div className="main container">
                <div className='row'>
                <div className="col-md-3">
                  <ul className="list-group">
                    <li className="list-group-item"><Link to="/editprofile" className="nav-link">Edit Profile</Link></li>
                    <li className="list-group-item active">Edit Company</li>
                    <li className="list-group-item"><Link to="/changepass" className="nav-link">Change Password</Link></li>
                </ul>
                </div>
                <div className="col-md-9">
                <strong class="pgheading">Edit Company Page</strong>
                <br /><br />
                <form onSubmit={formSubmit}>
                <table className="profiletable">
                <tbody>
                    <tr>
                        <td>Company Name</td>
                        <td colSpan={2}>
                            <input 
                            type="text" 
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={(e) => {
                                setUser({...data, name: e.target.value})
                            }}
                            value={data.name}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Registration Number</td>
                        <td colSpan={2}>
                        <input 
                            type="text" 
                            className="form-control"
                            id="registration_number"
                            name="registration_number"
                            onChange={(e) => {
                                setUser({...data, registration_number: e.target.value})
                            }}
                            value={data.registration_number}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Registered Address</td>
                        <td colSpan={2}>
                        <textarea 
                            type="registered_address" 
                            className="form-control"
                            id="registered_address"
                            name="registered_address"
                            onChange={(e) => {
                                setUser({...data, registered_address: e.target.value})
                            }}
                            value={data.registered_address}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>State</td>
                        <td colSpan={2}>
                        <select className="form-control selectpicker" name="state" 
                        onChange ={(e) => {
                            loadCities(e.target.value)
                            setStateValue(e.target.value)
                            setUser({...data, state: e.target.value})
                        }}
                        value={stateValue}>
                        <option value="">- Select State-</option>
                        { states && states.map(state =>                      
                        <option value={state.id} key={state.id}> {state.name}</option>
                        )}
                        </select>
                        </td>
                    </tr>
                    <tr>
                        <td>City</td>
                        <td colSpan={2}>
                        <select className="form-control selectpicker" id="city" name="city" 
                        onChange={(e) => {
                                setUser({...data, city: e.target.value})
                                setCityValue(e.target.value)
                            }} 
                        value={cityValue}>
                        <option value="">- Select City -</option>
                        { cities && cities.map(city =>                      
                        <option value={city.id} key={city.id}> {city.name}</option>
                        )}
                        </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Postal Code</td>
                        <td colSpan={2}>
                            <input 
                            type="text" 
                            className="form-control"
                            id="postal_code"
                            name="postal_code"
                            onChange={(e) => {
                                setUser({...data, postal_code: e.target.value})
                            }}
                            value={data.postal_code}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Establishment Year</td>
                        <td colSpan={2}>
                            <select
                            className="form-control"
                            id="establishment_year"
                            name="establishment_year"
                            onChange={(e) => {
                                setUser({...data, establishment_year: e.target.value})
                            }}
                            value={data.establishment_year || ""}
                            >
                            <option value="">- Select Year -</option>
                            {years.map(year => (
                                <option key={year} value={year}>{year}</option>
                            ))}
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>Nature Of Business</td>
                        <td colSpan={2}>
                            <input 
                            type="text" 
                            className="form-control"
                            id="nature_of_business"
                            name="nature_of_business"
                            onChange={(e) => {
                                setUser({...data, nature_of_business: e.target.value})
                            }}
                            value={data.nature_of_business}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>PAN Number</td>
                        <td colSpan={2}>
                            <input 
                            type="text" 
                            className="form-control"
                            id="pan"
                            name="pan"
                            onChange={(e) => {
                                setUser({...data, pan: e.target.value})
                            }}
                            value={data.pan}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>GST Number</td>
                        <td colSpan={2}>
                            <input 
                            type="text" 
                            className="form-control"
                            id="gst"
                            name="gst"
                            onChange={(e) => {
                                setUser({...data, gst: e.target.value})
                            }}
                            value={data.gst}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Legal Status</td>
                        <td colSpan={2}>
                            <input 
                            type="text" 
                            className="form-control"
                            id="legal_status"
                            name="legal_status"
                            onChange={(e) => {
                                setUser({...data, legal_status: e.target.value})
                            }}
                            value={data.legal_status}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Category</td>
                        <td colSpan={2}>
                            <input 
                            type="text" 
                            className="form-control"
                            id="category"
                            name="category"
                            onChange={(e) => {
                                setUser({...data, category: e.target.value})
                            }}
                            value={data.category}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan={2}>
                            <button type="submit" className="btn btn-primary">Save</button>
                        </td>
                    </tr>
                    <tr><td colSpan={2}>
                    { 
                        isLoader &&
                             <Loader></Loader>
                    }                   
                    </td></tr>
                    <tr>
                        <td colSpan={3}>
                        {
                        isSuccess &&
                            <Alert variant="success">{formMessage}</Alert>
                    }
                    {
                      isError &&
                            <Alert variant="danger">{formMessage}</Alert>
                    }
                            </td>
                    </tr>
                </tbody>
                </table>
                </form>
            </div>
            </div>
        </div>
    );
  }
  export default EditCompany;