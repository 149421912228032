import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { axiosRequest } from "../../api";
import Alert from 'react-bootstrap/Alert';

const Signup = (props) => {
  const initialFormData = {
    email: "",
    phone: "",
    fname: "",
    lname: "",
    city: "",
    state: "",
    postalcode: "",
    regnumber: "",
    regaddress: "",
    gst: "",
    pan: "",
    company: "",
    password: "",
    rpassword: "",
    otp: "", // Add OTP field
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [err, setERR] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [otpSent, setOtpSent] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(60); // Initial resend timer value

  const token = sessionStorage.getItem('token');

  if (token) {
    window.location.pathname = '/dashboard';
  }

  useEffect(() => {
    // Fetch states on component mount
    axiosRequest.get('location/states')
      .then(response => {
        setStates(response.data);
      })
      .catch(error => {
        console.error('Error fetching states:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Fetch cities when state is selected
    if (name === 'state') {
      axiosRequest.get(`location/states/cities/${value}`)
        .then(response => {
          setCities(response.data);
        })
        .catch(error => {
          console.error(`Error fetching cities for state ${value}:`, error);
        });
    }
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let newErrors = {};
  
    // Validate first name
    if (!formData.fname.trim()) {
      newErrors.fname = "First name can't be blank";
      formIsValid = false;
    }
  
    // Validate last name
    if (!formData.lname.trim()) {
      newErrors.lname = "Last name can't be blank";
      formIsValid = false;
    }
  
    // Validate email
    if (!formData.email.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) {
      newErrors.email = "Email not valid";
      formIsValid = false;
    }
  
    // Validate phone number (example validation for 10-digit number)
    if (!formData.phone.match(/^\d{10}$/)) {
      newErrors.phone = "Phone number must be 10 digits";
      formIsValid = false;
    }
  
    // Validate company name
    if (!formData.company.trim()) {
      newErrors.company = "Company name can't be blank";
      formIsValid = false;
    }
  
    // Validate registration number
    if (!formData.regnumber.trim()) {
      newErrors.regnumber = "Registration number can't be blank";
      formIsValid = false;
    }
  
    // Validate registration address
    if (!formData.regaddress.trim()) {
      newErrors.regaddress = "Registration address can't be blank";
      formIsValid = false;
    }
  
    // Validate GST number
    if (!formData.gst.trim()) {
      newErrors.gst = "GST number can't be blank";
      formIsValid = false;
    } else {
      const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]\d{1}[A-Z]\w{1}$/;
      if (!gstRegex.test(formData.gst)) {
        newErrors.gst = "GST number format is invalid";
        formIsValid = false;
      }
    }  

    // Validate PAN number
    if (!formData.pan.trim()) {
      newErrors.pan = "PAN number can't be blank";
      formIsValid = false;
    } else if (!formData.pan.match(/^([A-Z]){5}([0-9]){4}([A-Z]){1}$/)) {
      newErrors.pan = "PAN number format is invalid";
      formIsValid = false;
    }
  
    // Validate city
    if (!formData.city.trim()) {
      newErrors.city = "City can't be blank";
      formIsValid = false;
    }
  
    // Validate state
    if (!formData.state.trim()) {
      newErrors.state = "State can't be blank";
      formIsValid = false;
    }
  
    // Validate postal code
    if (!formData.postalcode.trim()) {
      newErrors.postalcode = "Postal Code can't be blank";
      formIsValid = false;
    }
  
    // Validate password
    if (!formData.password.match(/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/)) {
      newErrors.password = "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters";
      formIsValid = false;
    }
  
    // Validate repeat password
    if (formData.password !== formData.rpassword) {
      newErrors.rpassword = "Passwords do not match";
      formIsValid = false;
    }
  
    // Validate checkbox
    if (!isChecked) {
      newErrors.checkbox = "You need to agree to terms and conditions";
      formIsValid = false;
    }
  
    setErrors(newErrors); // Update errors state with validation results
    return formIsValid;
  };

  const sendOTP = () => {
    const userPhone = '+91' + formData.phone;
  
    // Check if email or phone number is already registered
    axiosRequest.post('/auth/check-registration', {
      email: formData.email,
      phone: formData.phone,
    })
    .then(response => {
      console.log(response.data);
      var iferr = 0;
      if (response.data.emailFound) {
        setErrors(prevErrors => ({
          ...prevErrors,
          email: 'Email already registered',
        }));
        iferr = 1;
      }
      if (response.data.phoneFound) {
        setErrors(prevErrors => ({
          ...prevErrors,
          phone: 'Phone number already registered',
        }));
        iferr = 1;
      }
      if(iferr == 0)
       { 
        // Proceed to send OTP
        if (handleValidation()) {
          axiosRequest.post('/otp/send', {
              phoneNumber: userPhone,
          })
          .then(response => {
            setOtpSent(true);
            setResendDisabled(true); // Disable resend initially
            startResendTimer();
          })
          .catch(error => {
            console.error('Error sending OTP:', error);
          });
        }
      }
    })
    .catch(error => {
      console.error('Error checking registration:', error);
    });
  };
  

  const verifyOTP = () => {
    const userPhone = '+91' + formData.phone
    axiosRequest.post('/otp/verify', {
        phoneNumber: userPhone,
        otp: formData.otp,
    })
    .then(response => {
      // Proceed with registration upon successful OTP verification
      axiosRequest.post('/auth/register', formData)
        .then(response => {
          if (response.data.msg === 'Success!') {
            setERR("Registered successfully. Check Email for verification link.");
            setSuccess(true);
          }
        })
        .catch(error => {
          setERR(error.response.data.msg);
          setError(true);
          setOtpSent(false);
          formData.otp='';
        });
    })
    .catch(error => {
      console.error('Error verifying OTP:', error);
    });
  };

  const handleResendOTP = () => {
    const userPhone = '+91' + formData.phone
    axiosRequest.post('/otp/send', {
        phoneNumber: userPhone,
    })
    .then(response => {
      setResendDisabled(true);
      startResendTimer();
    })
    .catch(error => {
      console.error('Error resending OTP:', error);
    });
  };

  const startResendTimer = () => {
    let seconds = 60;
    const timer = setInterval(() => {
      seconds--;
      setResendTimer(seconds);
      if (seconds === 0) {
        clearInterval(timer);
        setResendDisabled(false); // Enable resend after timer expires
      }
    }, 1000);
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    setERR("");
    setError(false);
    setErrors('');
    setSuccess(false);
    console.log(formData);
    if (otpSent) {
      verifyOTP();
    } else {
      sendOTP();
    }
  };

  return (
    <div>
      <strong class="pgheading">Online Enrollment of Company/Bidder</strong>
      <br />
      <br />
      <br />
            <div className="text-black card-body">
              <form onSubmit={loginSubmit}>
                <div className="row mb-4">
                  <div className="col-md-9">
                  <div className="frmsection">
                    <p className="heading">Personal Details</p>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="fname">First Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fname"
                          name="fname"
                          placeholder="Enter First Name"
                          value={formData.fname}
                          onChange={handleChange}
                        />
                        <small className="text-danger form-text">{errors.fname}</small>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="lname">Last Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lname"
                          name="lname"
                          placeholder="Enter Last Name"
                          value={formData.lname}
                          onChange={handleChange}
                        />
                        <small className="text-danger form-text">{errors.lname}</small>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="email">Your Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Enter email address"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.email}</small>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="phone">Phone Number</label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phone"
                        name="phone"
                        placeholder="Enter phone number"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.phone}</small>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="password">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.password}</small>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="rpassword">Repeat your password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="rpassword"
                        name="rpassword"
                        placeholder="Retype Password"
                        value={formData.rpassword}
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.rpassword}</small>
                    </div>
                    </div>  
                    <div className="frmsection">
                    <p className="heading">Company Details</p>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="company">Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="company"
                        name="company"
                        placeholder="Enter Company Name"
                        value={formData.company}
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.company}</small>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="regnumber">Registration Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="regnumber"
                        name="regnumber"
                        placeholder="Enter Registration Number"
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.regnumber}</small>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="regaddress">Registration Address</label>
                      <textarea
                        className="form-control"
                        id="regaddress"
                        name="regaddress"
                        placeholder="Enter Registration Address"
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.regaddress}</small>
                    </div>
                    <div className="row mb-4">
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="state">State</label>
                        <select
                          className="form-control"
                          id="state"
                          name="state"
                          value={formData.state}
                          onChange={handleChange}
                        >
                          <option value="">Select State</option>
                          {states.map(state => (
                            <option key={state.id} value={state.id}>{state.name}</option>
                          ))}
                        </select>
                        <small className="text-danger form-text">{errors.state}</small>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label" htmlFor="city">City</label>
                        <select
                          className="form-control"
                          id="city"
                          name="city"
                          value={formData.city}
                          onChange={handleChange}
                        >
                          <option value="">Select City</option>
                          {cities.map(city => (
                            <option key={city.id} value={city.id}>{city.name}</option>
                          ))}
                        </select>
                        <small className="text-danger form-text">{errors.city}</small>
                      </div>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="postalcode">Postal Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="postalcode"
                        name="postalcode"
                        placeholder="Enter Postal Code"
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.postalcode}</small>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="gst">GST Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="gst"
                        name="gst"
                        placeholder="Enter GST Number"
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.gst}</small>
                    </div>
                    <div className="form-group mb-4">
                      <label className="form-label" htmlFor="pan">PAN Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="pan"
                        name="pan"
                        placeholder="Enter PAN Number"
                        onChange={handleChange}
                      />
                      <small className="text-danger form-text">{errors.pan}</small>
                    </div>
                  </div> 
                  </div>
                  <div className="col-md-3">
                  <div className="signupside">
                    <strong>Email ID and Mobile No Policy</strong>
                    <p>Email and mobile must comply following requisites,</p>
                    <ul>
                      <li>As Mobile and Email are the modes of correspondence, ensure that mobile no and email id provided are correct.</li>
                      <li>The verification codes are valid for 900 seconds from the time Send Verification Code is clicked.</li>
                  </ul>
                  </div>
                  </div>
                </div>
                <div className="form-check d-flex justify-content-center mb-5">
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    id="formcheck"
                    checked={isChecked}
                    onChange={handleOnChange}
                  />
                  <label className="form-check-label" htmlFor="formcheck">
                    I agree to all statements in <Link to="#!">Terms of service</Link>
                  </label>
                  <small className="text-danger form-text">{errors.checkbox}</small>
                </div>

                {/* OTP Input Section */}
                {otpSent && (
                  <div className="mb-4">
                    <label htmlFor="otp" className="form-label">Enter OTP</label>
                    <input
                      type="text"
                      className="form-control mb-2"
                      id="otp"
                      name="otp"
                      value={formData.otp}
                      onChange={handleChange}
                      placeholder="Enter OTP"
                    />
                    <small className="text-muted">
                      An OTP has been sent to your phone number. <br />
                      <button
                        className="btn btn-link p-0"
                        disabled={resendDisabled}
                        onClick={handleResendOTP}
                      >
                        Resend OTP ({resendTimer}s)
                      </button>
                    </small>
                  </div>
                )}

                {/* Register Button Section */}
                <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                  <button type="submit" className="btn btn-primary btn-lg">
                    {otpSent ? 'Verify OTP' : 'Register'}
                  </button>
                </div>

                {/* Success and Error Alerts */}
                {isSuccess && (
                  <Alert key="success" variant="success">
                    {err}
                  </Alert>
                )}
                {isError && (
                  <Alert key="danger" variant="danger">
                    {err}
                  </Alert>
                )}

                {/* Login Link */}
                <p className="text-center text-muted mt-5 mb-0">
                  Already have an account? <Link to="/login">Login Here</Link>
                </p>
              </form>
            </div>
    </div>
  );
}

export default Signup;