import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import BidsDetails from "../components/bids/bidsdetail";
import Sidebar from "../components/users/sidebar";

function BidsDetailPage() {

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  document.title = "User Home Page"; 

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }

  return(
    <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
          <strong class="pgheading">Bids Details</strong>
          <br /><br />
          <BidsDetails />
        </Col>
        </Row>
        </div>
  );
}

export default BidsDetailPage;