import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const TenderOrganization = (props) => {
  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [pcategories, setPCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pcatValue, setPCatValue] = useState('');
  const [catValue, setCatValue] = useState('');

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const data = {
  }

var {id} = useParams();
if(!id)
{
    id = 2;
}
  useEffect(() => {
    setLoader(true);

        axiosRequest.get('tenders/productcategories', data, config)
        .then(function (response){
          setPCategories(response.data)
        })
    axiosRequest.get('tenders/categories', data, config)
        .then(function (response){
          setCategories(response.data)
        })
        axiosRequest.get('company/tendercount/', data, config)
        .then(function (response){
          if(response.data)
            setData(response.data)
            setLoader(false);
        })
        .catch(function (error) {
          if(error.response.data.message=='Invalid Token')
          {
              navigate("/logout");
          }
        });
    
    }, []);

  const usrView = (e) => {
    navigate("/tenders-by-organization/"+e);
    window.location.pathname = '/tenders-by-organization/'+e;
  }

  const handleChange = (e) => { 
    const { value, checked } = e.target;
    console.log(`${value} is ${checked}`);
  }; 
    
    return (
            <div className="main">
            <Row>
            <Col sm={12}> 
            { 
              isLoader &&
                <Loader></Loader>
            }
            </Col>
            </Row>
            <Row>
            <Col sm={12}>
              <strong class="pgheading">Tenders Search By Organization</strong>
              <br />
              <Row>
              <div class="searchbox2">
              <table>
              <tr><td style={{ padding: "10px" }}>
                <label>Tender Category: </label>
                <select className="form-control selectpicker" style={{ display: "inline-block", fontSize: "14px" }} name="pcategory" onChange={(e) => setPCatValue(e.target.value)} value={pcatValue}>
                    <option value="">- Select Product Category -</option>
                    {pcategories && pcategories.map(pcategory =>
                      <option value={pcategory.id}>{pcategory.name}</option>
                    )}
                </select>
                </td>
                <td style={{ padding: "10px" }}>
                <label>Product Category</label>
                <select className="form-control selectpicker" style={{ display: "inline-block", fontSize: "14px" }} name="category" onChange={(e) => setCatValue(e.target.value)} value={catValue}>
                    <option value="">- Select Category -</option>
                    {categories && categories.map(category =>
                      <option value={category.id}>{category.name}</option>
                    )}
                </select>
                </td>
                </tr>
                </table> 
              <Button type="submit" variant="primary">Search</Button>
              &nbsp;
              <a href="/" className="btn btn-primary">Cancel</a>
              </div>
              </Row>
              <table className="table table-striped table-bordered">
                  <thead>
                      <tr>
                          <th>Organization Name</th>
                          <th>Tender Count</th>
                      </tr>
                  </thead>
                  <tbody>
                      {users && users.map(user =>
                          <tr key={user.companyId}>
                            <td>{user.companyName}</td>
                            <td><a href="#" onClick={usrView.bind(this, user.companyId)}>{user.activeTendersCount}</a></td>
                          </tr>
                      )}
                  </tbody>
              </table>
            </Col> 
            </Row>
        </div>
    );
  }
  export default TenderOrganization;