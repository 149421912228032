import React from 'react';
import { Link } from 'react-router-dom';
 
const Footer = () => {
    return (
        <footer>
  <div className="container">
    <div className="row">
      <div className="col-xl-4 col-lg-4 col-md-4">
        <div>
          <strong>ConnectIND</strong>
          <p>Designed, Developed and Hosted by ConnectedIND</p>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-4">
        <div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-4 col-md-4">
        <div>
        </div>
      </div>
    </div>
  </div>
  <div className="copyright">
        Copyright &copy; <a href="#" target="_blank">ConnectIND</a>
  </div>
</footer>
    );
}
 
export default Footer;