import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const AdminSidebar = (props) => {
    const usertype = sessionStorage.getItem('type');

    if (usertype.trim() == 'bidder') {
        return (
            <ul className="list-group">
                <li className="list-group-item active" active>User {usertype}</li>
                <li className="list-group-item"><Link to="/editprofile" className="nav-link">Edit Profile</Link></li>
                <li className="list-group-item"><Link to="/editcompany" className="nav-link">Edit Company</Link></li>
                <li className="list-group-item active">Users</li>
                <li className="list-group-item"><Link to="/users" className="nav-link">All Users</Link></li>
                <li className="list-group-item active">Tenders</li>
                <li className="list-group-item"><Link to="/tenders" className="nav-link">All Tenders</Link></li>
                <li className="list-group-item"><Link to="/tenderscat" className="nav-link">Tenders Categories</Link></li>
                <li className="list-group-item"><Link to="/tenderstype" className="nav-link">Tenders Type</Link></li>
                <li className="list-group-item active">Support</li>
                <li className="list-group-item">Ask Question</li>
                <li className="list-group-item">Support History</li>
            </ul>
        );
    } else {
        return (
            <ul className="list-group">
                <li className="list-group-item active" active>User {usertype}</li>
                <li className="list-group-item"><Link to="/myblocklist" className="nav-link">Blocked Companies</Link></li>
                <li className="list-group-item"><Link to="/account" className="nav-link">My Account</Link></li>
                <li className="list-group-item"><Link to="/documents" className="nav-link">My Documents</Link></li>
                <li className="list-group-item active">Tenders</li>
                <li className="list-group-item"><Link to="/livetenders" className="nav-link">Live Tenders</Link></li>
                <li className="list-group-item"><Link to="/mytenders" className="nav-link">My Tenders</Link></li>
                <li className="list-group-item active">Support</li>
                <li className="list-group-item">Ask Question</li>
                <li className="list-group-item">Support History</li>
            </ul>
        );
    }
  }
  export default AdminSidebar;