import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const Profile = (props) => {
  const token = sessionStorage.getItem('token');
  const role = sessionStorage.getItem('role');
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [data, setUser] = useState([]);
  const [data1, setCompany] = useState([]);
  const [isLoader, setLoader] = useState(false);

  var udata = JSON.parse(user);
  
  var {id} = useParams();
  if(!id)
  {
    id = udata[0].id;
  }
  if(role==='user')
  {
    //id = udata[0].id;
  }
  const config = {
    headers: { Authorization: `Bearer ${token}` }
};

const userdata = {id}
useEffect(() => {
  setLoader(true);
  if(id)
  {
    axiosRequest.get('/user/view/'+id, userdata, config)
      .then(function (response){
        if(response.data!== null)
        {
        setUser(response.data);
        axiosRequest.get('/company/user/'+id, userdata, config)
        .then(function (response){
          if(response.data!== null)
              setLoader(false);
              setCompany(response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}, [])

  document.title = data.first_name+"'s Profile | ConnectIND"; 

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    
    return (
            <div>
                <h2 className="p-3">Profile Page</h2>
                <table className="profiletable">
                <tbody>
                    <tr><td>First Name</td><td>{data.first_name}</td></tr>
                    <tr><td>Last Name</td><td>{data.last_name}</td></tr>
                    <tr><td>Email</td><td>{data.email}</td></tr>
                    <tr><td>Phone Number</td><td>{data.phone}</td></tr>
                    <tr><td>Date Of Birth</td><td>{data.dob}</td></tr>
                    <tr><td colSpan={2}>
                    <h3 className="p-3">Company Details</h3>
                    { 
                        isLoader &&
                             <Loader></Loader>
                    }                  
                    </td></tr>
                    <tr><td>Name</td><td>{data1.name}</td></tr>
                    <tr><td>Registration Number</td><td>{data1.registration_number}</td></tr>
                    <tr><td>Registered Address</td><td>{data1.registered_address}</td></tr>
                    <tr><td>Establishment Year</td><td>{data1.establishment_year}</td></tr>
                    <tr><td>PAN</td><td>{data1.pan}</td></tr>
                    <tr><td>GST Number</td><td>{data1.gst}</td></tr>
                </tbody>
                </table>
            </div>
    );
  }
  export default Profile;