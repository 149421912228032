import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const Account = (props) => {
    const token = sessionStorage.getItem('token');
    const role = sessionStorage.getItem('role');
    const navigate = useNavigate();
    const user = sessionStorage.getItem('user');
    const [data, setUser] = useState(null);
    const [data1, setCompany] = useState(null);
    const [isLoader, setLoader] = useState(false);

    let udata = JSON.parse(user);
    let { id } = useParams();

    if (!id || role === 'user') {
        id = udata[0].id;
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    useEffect(() => {
        if (!token) {
            navigate("/login");
            window.location.pathname = '/login';
            return;
        }
        setLoader(true);
        axiosRequest.get('/user/view/' + id, config)
            .then(response => {
                if (response.data) {
                    setUser(response.data);
                    return axiosRequest.get('/company/user/' + id, config);
                }
            })
            .then(response => {
                if (response && response.data) {
                    setCompany(response.data);
                }
                console.log('setting loader false');
                setLoader(false);
            })
            .catch(error => {
                console.log(error);
                setLoader(false);
            });

    }, []);

    useEffect(() => {
        if (data && data.first_name) {
            document.title = `${data.first_name}'s Account | ConnectIND`;
        }
    }, [data]);

    if (!token) {
        return null; // Avoid rendering the component if the token is missing
    }

    return (
        <div>
            <strong class="pgheading">Account</strong>
            <br /><br />
            <Link to="/editprofile">Edit</Link>
            <table className="profiletable">
                <tbody>
                    <tr><td style={{ width: 350 }}>First Name</td><td>{data?.first_name}</td></tr>
                    <tr><td>Last Name</td><td>{data?.last_name}</td></tr>
                    <tr><td>Email</td><td>{data?.email}</td></tr>
                    <tr><td>Phone Number</td><td>{data?.phone}</td></tr>
                    <tr><td>Date Of Birth</td><td>{data?.dob}</td></tr>
                </tbody>
            </table>
            <br /><br />
            <strong class="pgheading">Company Profile</strong>
            <br /><br />
            <Link to="/editcompany">Edit</Link>
            <table className="profiletable">
                <tbody>
                    <tr><td style={{ width: 350 }}>Name</td><td>{data1?.name}</td></tr>
                    <tr><td>Registration Number</td><td>{data1?.registration_number}</td></tr>
                    <tr><td>Registered Address</td><td>{data1?.registered_address}</td></tr>
                    <tr><td>City</td><td>{data1?.company_city?.name}</td></tr>
                    <tr><td>State</td><td>{data1?.company_state?.name}</td></tr>
                    <tr><td>Postal Code</td><td>{data1?.postal_code}</td></tr>
                    <tr><td>Establishment Year</td><td>{data1?.establishment_year}</td></tr>
                    <tr><td>PAN</td><td>{data1?.pan}</td></tr>
                    <tr><td>GST</td><td>{data1?.gst}</td></tr>
                    <tr><td colSpan={2}>
                        {isLoader && <Loader />}
                    </td></tr>
                </tbody>
            </table>
        </div>
    );
}

export default Account;
