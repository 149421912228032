import React from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import MyAccount from "../components/users/account";
import Sidebar from "../components/users/sidebar";

function Homepage() {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  document.title = "User Home Page"; 

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
  else
  {
    const user = sessionStorage.getItem('user');
    var data = JSON.parse(user)[0];
    if(data.role === 'admin')
    {
        navigate("/dashboard");
        window.location.pathname = '/dashboard';
    }
  }
    
  return(
      <div className='main container'>
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
            <MyAccount />
        </Col>
        </Row>
    </div>
  );
}

export default Homepage;