import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const TenderbyOrganization = (props) => {
  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const data = {
  }

var {id} = useParams();
if(!id)
{
    id = 1;
}
  useEffect(() => {
    setLoader(true);
        axiosRequest.get('tenders/active/company/'+id, data, config)
        .then(function (response){
          if(response.data)
            setData(response.data)
            setLoader(false);
        })
        .catch(function (error) {
          if(error.response.data.message=='Invalid Token')
          {
              navigate("/logout");
          }
        });
    
    }, []);

  const usrView = (e) => {
    navigate("/tender/details/"+e);
    window.location.pathname = '/tender/details/'+e;
  }

  const handleChange = (e) => { 
    const { value, checked } = e.target;
    console.log(`${value} is ${checked}`);
  }; 
    
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const thsyle = {
    fontSize: "14px",
    fontFamily: "tahoma"

  };
  const tdsyle = {
    fontSize: "12px",
    fontFamily: "tahoma"
  };
    return (
            <div className="main">
            <Row>
            { 
              isLoader &&
                <Loader></Loader>
            }
            </Row>
            <Row>
            <Col sm={12}>
              <strong class="pgheading">Tenders By Organization</strong> 
              <a href="/tenders-by-organizations/" className="float-end btn btn-primary btn-sm">Back</a>
              <br />
              <br />
              <table className="table table-striped table-bordered">
              <thead style={thsyle}>
                      <tr>
                          <th>e-Published Date</th>
                          <th>Bid Submission Closing Date</th>
                          <th>Bids Opening On</th>
                          <th colSpan={2}>Title/Ref.No./Tender Id</th>
                          <th>Organization Name</th>
                      </tr>
                  </thead>
                  <tbody style={tdsyle}>
                      {users && users.map(user =>
                          <tr key={user.id}>
                            <td>{formatDate(user.published_date)}</td>
                            <td>{formatDate(user.bid_submission_closing_date)}</td>
                            <td>{formatDate(user.bid_opening_date)}</td>
                            <td colSpan={2}><a href="#" onClick={usrView.bind(this, user.id)}>{user.title}</a> / </td>
                            <td>{user.tender_company.name}</td>
                          </tr>
                      )}
                  </tbody>
              </table>
            </Col> 
            </Row>
        </div>
    );
  }
  export default TenderbyOrganization;