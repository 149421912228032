import React from "react";
import Passwd from "../components/users/password";
import { useNavigate } from "react-router-dom";

function ChangePassPage() {

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    
  return(
    <Passwd />
  );
}

export default ChangePassPage;