import AWS from 'aws-sdk';
import s3 from '../../api/awsConfig'; // Import the configured S3 instance

/**
 * Deletes a file from AWS S3 given its URL.
 *
 * @param {string} fileUrl - The URL of the file to delete.
 * @returns {Promise<boolean>} - Returns true if the file was successfully deleted, false otherwise.
 */
const deleteS3File = async (fileUrl) => {
  // Parse the S3 URL to get the bucket name and key
  const url = new URL(fileUrl);
  const bucketName = url.hostname.split('.')[0];
  const key = url.pathname.substring(1);

  try {
    // Delete the object
    await s3.deleteObject({ Bucket: bucketName, Key: key }).promise();
    return true;
  } catch (error) {
    console.error(`Failed to delete ${fileUrl}:`, error);
    return false;
  }
};

export default deleteS3File;