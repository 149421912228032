import React from "react";
import { Col, Row } from "react-bootstrap";
import Tendersidebar from "../components/users/frontpagesidebar";
import Tendersbyorganization from "../components/tenders/tendersbyorganization";

const TendersbyorganizationPage = (props) => {
    document.title = "Tenders by Organization | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={2} className='home'>
          <Tendersidebar />
        </Col>
        <Col md={9}>
        <Tendersbyorganization />
        </Col>
        </Row>
    </div>
    )
}

export default TendersbyorganizationPage;