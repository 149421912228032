import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";
import { Modal, Alert, Button } from "react-bootstrap";
import Loader from '../App/loader';
import ConfirmationModal from '../App/ConfirmationModal';

const BidsbyTender = (props) => {

  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [tenderdata, setTender] = useState(false);
  const [isShortNotice, setShortNotice] = useState(false);
  const [tenderStage, setTenderStage] = useState('Technical');
  const [stage, setStage] = useState('2');
  const [shortlistedBids, setShortlistedBids] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [currentAction, setCurrentAction] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [selectedRecordId, setSelectedRecordId] = useState(null);
  const [isAwarded, setAwarded] = useState(false);
  const [canPerformActions, setCanPerformActions] = useState(false); 


  var udata = JSON.parse(user);
  var userid = udata[0].id;
  var {id} = useParams();

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
};
const data = {
}

  useEffect(() => {
    setLoader(true);
        axiosRequest.get('/tenders/view/'+id, config)
          .then(function (response){
            setTender(response.data);
            setStage('2')
            setShortNotice(response.data.isShortNotice);
            const today = new Date();
            const bidOpeningDate = new Date(response.data.bid_opening_date);
            if (today >= bidOpeningDate) {
              setCanPerformActions(true); 
            }

            if(response.data.status==4)
            {  
              setAwarded(true);
            }
            if(response.data.isShortNotice)
            {
              setTenderStage('Financial')
              setStage('3')
            }
            if(response.data.technical_result_published_at !== null)
            {
              setTenderStage('Financial')
              setStage('3')
            }
            if(response.data.financial_result_published_at !== null)
            {
                setTenderStage('Final')
                setStage('4')
            }

            axiosRequest.get('/bids/tender/'+id, config)
            .then(function (response){
              setData(response.data)  
            })
            .catch(function (error) {
              console.log(error);
            });
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, []);

  useEffect(() => {
    axiosRequest.get('/bids/shortlisted/?tender='+id+'&stage='+stage, config)
    .then(function (response){
      const shortlisted = {};
      response.data.forEach(bid => {
        shortlisted[bid.bid_id] = true;
      });
      setShortlistedBids(shortlisted);
    })
    .catch(function (error) {
      console.log(error);
    });
    // Getting All Bids By tender 
    if(!isAwarded){
    axiosRequest.get('bids/tender/winner/'+id, data, config)
      .then(function (response){
        setData(response.data)    
      setLoader(false);
    })
    .catch(function (error) {
      console.log(error);
    });
  }
  else
  {

  }
}, [tenderStage]);


  const usrView = (e) => {
    if (canPerformActions) {
      navigate("/bids/details/" + e);
      window.location.pathname = '/bids/details/' + e;
    }
  }

  const companyview = (e) => {
    if (canPerformActions) {
      const url = `/profile/${e}`;
      window.open(url, '_blank');
    }
  }

  const shortlist = (bidid) => {
    const isAlreadyShortlisted = shortlistedBids[bidid];
    setShortlistedBids(prevState => ({
      ...prevState,
      [bidid]: !isAlreadyShortlisted
    }));
  };

  const handleConfirmation = (confirmed) => {
  const formData = new FormData();
  formData.append('tender', id);
  formData.append('stage', stage);

    if (confirmed) {
      console.log('Confirmed!');
      shortlist(currentUserId);  
      // Perform the shortlisting action
      const actionUrl = currentAction === 'shortlist' ? `/bids/shortlist/${currentUserId}` : `/bids/unshortlist/${currentUserId}`;
    
      axiosRequest.post(actionUrl, formData, config)
        .then(function (response){
          if(response.status === 200){
            setLoader(false);
            }
        })
        .catch(function (error) {
          console.log(error);
        });

    } else {
      console.log('Cancelled');
    }
    setShowConfirmationModal(false); // Close the confirmation modal
    setShowModal(false); // Close the main modal
  };

  const handleShortlistClick = (bidid) => {
    const action = shortlistedBids[bidid] ? 'unshortlist' : 'shortlist';
    const confirmationMessage = `Are you sure you want to ${action} user with ID ${bidid}?`;
    setConfirmationMessage(confirmationMessage);
    setShowModal(true);
    
    setCurrentUserId(bidid);
    setCurrentAction(action);
  };

  const LockResults = (e) => {

    if (window.confirm("Are you sure you want to lock these bids for the "+tenderStage+" Round?")) {

      const formData = new FormData();
      formData.append('tender', id);
      formData.append('stage', stage);
      const actionUrl = `/bids/lockbids/`;
      console.log(actionUrl);
      axiosRequest.post(actionUrl, formData, config)
      .then(function (response){
        if(response.status === 200){
          setLoader(false);
          }
      })
      .catch(function (error) {
        console.log(error);
      });
    }

  }

  const handleCheckboxChange = (recordId) => {
    setSelectedRecordId(recordId);
  };

  const AwardBids = (e) => {
    if (selectedRecordId !== null) {
      if (window.confirm("Are you sure you want award the tender to this bid. This action cannot be reversed.")) {

        axiosRequest.get('/bids/status?id='+selectedRecordId+'&status=4', config)
        .then(function (response){
          if(response.status === 200){
            setLoader(false);
            }
        })
        .catch(function (error) {
          console.log(error);
        });
        
      }
    } else {
      alert('No record selected');
    }
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  };


    return (
        <div className="main">
            <strong class="pgheading">Tender Details #{tenderdata.id}</strong>
            <br />
            <div className="card">
              <div className="card-header">
              {tenderdata.title}
              </div>
              <div className="card-body">
                <p><strong>Bid Submission Closing Date</strong>: {formatDate(tenderdata.bid_submission_closing_date)}</p>
                <p><strong>Bid Opening Date</strong>: {formatDate(tenderdata.bid_opening_date)}</p>
              </div>
            </div>
            <hr />
            {
              tenderStage && canPerformActions && (
                <div>
                  <strong class="pgheading">{tenderStage} review of bids</strong>
                </div>
              )
            }
            { !canPerformActions && (
                <Alert variant="primary">
                <p>You cannot perform any action or view Bids until bid opening date.</p>
                </Alert>
            )
            }
            {isShortNotice && stage<4 && !isAwarded &&(
                <Alert variant="primary">
                <p>This is a <strong>Short Notice</strong> Tender. So, you can open Financial without going through Technical Review stage.</p>
                </Alert>
            )}

            {stage==4 && !isAwarded && (
                <Alert variant="primary">
                <p>This is the final stage of Bids selection. You have to award the tender to one of the following bids you shortlisted in previous stages. {isAwarded}</p>
                </Alert>
            )}


            {isAwarded && (
                <Alert variant="primary">
                <p>This Tender has already been Awarded. {isAwarded}</p>
                </Alert>
            )}
            <br /><br />
            <p>All the bids you have received for this tender.</p>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>id</th>
                        {stage==4 && !isAwarded && (<th></th>)}
                        <th>Company</th>
                        <th>Title</th>
                        <th>Technical Document</th>
                        {isShortNotice && <th>Financial Document</th>}
                        <th>Pubished Date</th>
                        {stage<4 &&  (<th></th>)}
                        {stage===4 &&  (<th></th>)}
                    </tr>
                </thead>
                <tbody>
                      {!isLoader && users.length === 0 && (
                      <tr>
                        <td colSpan="5" className="text-center">This Tender currently has no Bids.</td>
                      </tr>
                    )}
                    {users && users.map(user =>
                        <tr key={user.id} className={shortlistedBids[user.id] ? 'shortlisted' : ''}>
                            <td>{user.id}</td>
                            {stage==4 && !isAwarded && (<td>
                            <input
                              type="checkbox"
                              checked={selectedRecordId === user.id}
                              onChange={() => handleCheckboxChange(user.id)}
                            />
                            </td>)}
                            <td>
                              <a href="#" onClick={companyview.bind(this, user.bid_company.id)}>{user.bid_company.name}</a>
                            </td>
                            <td>{user.cover}</td>
                            <td>
                            {canPerformActions ? (
                                <a href={user.technical_doc}>Download</a>
                              ) : (
                                'Download Restricted'
                              )}
                            </td>
                            {isShortNotice && <td>
                              {canPerformActions ? (
                                <a href={user.financial_doc}>Download</a>
                              ) : (
                                'Download Restricted'
                              )}
                              </td>}
                            <td>{user.createdAt}</td>
                            <td>
                            {canPerformActions &&  (
                            <a href="#" onClick={usrView.bind(this, user.id)}>View</a>
                            )}
                            </td>
                            {stage<4 && canPerformActions &&  (<td>
                            <a href="#" onClick={() => handleShortlistClick(user.id)}>
                                {shortlistedBids[user.id] ? 'Shortlisted' : 'Shortlist'}
                            </a>
                            </td>)}
                            {stage===4 && canPerformActions &&  (<td>
                            <a href="#" onClick={() => handleShortlistClick(user.id)}>
                                {shortlistedBids[user.id] ? 'Shortlisted' : 'Shortlist'}
                            </a>
                            </td>)}
                        </tr>
                    )}
                    { 
                    isLoader &&
                        <Loader></Loader>
                    }
                </tbody>
            </table>
            {stage<4 && canPerformActions && users.length != 0 &&  (<Button variant="primary" onClick={LockResults}>
              Lock Shortlist Bids for {tenderStage}
            </Button>)}
            {stage==4 && users.length != 0 && !isAwarded && (
            <Button variant="primary" onClick={AwardBids}>Award Selected Bid</Button>
            )}
            {showModal && (
            <ConfirmationModal
              message={confirmationMessage}
              showModal={showModal}
              onConfirm={handleConfirmation}
            />
          )}
            </div>    
    );
  }
  export default BidsbyTender;