import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const Sidebar = (props) => {
    const usertype = sessionStorage.getItem('type');

    if (usertype.trim() == 'bidder') {
        return (
            <ul className="list-group usersidebar">
                <li className="list-group-item active" active>User</li>
                <li className="list-group-item"><Link to="/homepage" className="nav-link">Dashboard</Link></li>
                <li className="list-group-item"><Link to="/account" className="nav-link">My Account</Link></li>
                <li className="list-group-item"><Link to="/documents" className="nav-link">My Documents</Link></li>
                <li className="list-group-item active">Tenders</li>
                <li className="list-group-item"><Link to="/favorite-tenders" className="nav-link">Favourite Tenders</Link></li>
                <li className="list-group-item active">Bids</li>
                <li className="list-group-item"><Link to="/my-bids" className="nav-link">My Active Bids</Link></li>
                <li className="list-group-item"><Link to="/awarded-bids" className="nav-link">Awarded Bids</Link></li>
                <li className="list-group-item"><Link to="/expired-bids" className="nav-link">Expired Bids</Link></li>
                <li className="list-group-item"><Link to="/withdrawn-bids" className="nav-link">My Withdrawn Bids</Link></li>
                <li className="list-group-item"><Link to="/techno-commercial-queries/" className="nav-link">Techno Commercial Query</Link></li>
                <li className="list-group-item"><Link to="/techno-commecial-queries-history/" className="nav-link">Techno Commercial Query History</Link></li>
                <li className="list-group-item"><Link to="/shortfall-requests/" className="nav-link">Short Fall Documents </Link></li>
                <li className="list-group-item"><Link to="/shortfall-requests-history/" className="nav-link">Short Fall Documents History</Link></li>
                <li className="list-group-item"><Link to="#" className="nav-link">Tender Status</Link></li>
                <li className="list-group-item"><Link to="/bids-history" className="nav-link">Bids History</Link></li>
            </ul>
        );
    } else {
        return (
            <ul className="list-group usersidebar">
                <li className="list-group-item active" active>User</li>
                <li className="list-group-item"><Link to="/homepage" className="nav-link">Dashboard</Link></li>
                <li className="list-group-item"><Link to="/myblocklist" className="nav-link">Blocked Companies</Link></li>
                <li className="list-group-item"><Link to="/account" className="nav-link">My Account</Link></li>
                <li className="list-group-item"><Link to="/documents" className="nav-link">My Documents</Link></li>
                <li className="list-group-item active">Tenders</li>
                <li className="list-group-item"><Link to="/tender-drafts" className="nav-link">Drafts</Link></li>
                <li className="list-group-item"><Link to="/livetenders" className="nav-link">Live Tenders</Link></li>
                <li className="list-group-item"><Link to="/mytenders" className="nav-link">Tender History</Link></li>
                <li className="list-group-item active">Support</li>
                <li className="list-group-item"><Link to="/my-shortfall-requests/" className="nav-link">Short Fall Documents</Link></li>
                <li className="list-group-item"><Link to="/my-techno-commecial-queries/" className="nav-link">Techno Commercial Queries</Link></li>
            </ul>
        );
    }
  }
  export default Sidebar;