import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";

const ShortfallCreate = () => {
    const [description, setDescription] = useState(''); // Description entered by the user
    const [bidId, setBidId] = useState(null);
    const token = sessionStorage.getItem('token');
    const [backlink, setBack] = useState('');
    const navigate = useNavigate();
    
    const user = sessionStorage.getItem('user');
    var udata = JSON.parse(user);
    var userid = udata[0].id;
    const companyId = udata[0]?.company_user?.id;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    var { id } = useParams();
    if (!id) {
        id = 1;
    }

    useEffect(() => {
        setBack('bids/details/' + id); // Assuming you want to set backlink
        setBidId(id);
    }, [id]);

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        if ((bidId || id) && description) {
            // Create Techno Commercial Query
            const queryData = {
                bidId: bidId || id, // Use bidId if available, else use id
                description: description,
                userId: userid
            };
            axiosRequest.post('/support/shortfall/create', queryData, config)
                .then(function (response) {
                    console.log('Shortfall Query created successfully:', response.data);
                    navigate(backlink); // Redirect after successful creation
                    window.location.pathname = backlink;
                })
                .catch(function (error) {
                    console.error('Error creating query:', error);
                });
        } else {
            alert("Please fill in the tender reference number and description.");
        }
    };

    const back = () => {
        navigate(backlink);
        window.location.pathname = backlink;
    };

    return (
        <div>
            <strong className="pgheading">Create A New Short Fall Document Request</strong>
            <br /><br />
            <a onClick={back} className='float-end btn btn-primary btn-sm'>Back</a>
            <br /><br />
            <div className="searchbox1">
                <form onSubmit={handleSubmit}>
                    <label>Bid Id</label>
                    <input 
                        type="text" 
                        className='me-2 form-control' 
                        value={bidId || id} // Use bidId if available, else id
                        onChange={(e) => setBidId(e.target.value)} // Update bidId on change
                    />

                    <label>Description</label>
                    <textarea 
                        className='me-2 form-control' 
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)} 
                    />

                    <input type="submit" value="Submit" className="btn btn-primary btn-sm float-end" />
                    <br />
                </form>
            </div>
        </div>
    );
};

export default ShortfallCreate;
