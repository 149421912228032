// FileUpload.js
import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Import the UUID library
import s3 from '../../api/awsConfig'; // Import the configured S3 instance

const FileUpload = ({ s3Folder, onUploadComplete }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setUploadStatus(null); // Clear previous status when a new file is selected
    setProgress(0); // Reset progress bar when a new file is selected
  };

  const uploadFile = async (file) => {
    if (!file) {
      throw new Error('No file selected');
    }

    const fileExtension = file.name.split('.').pop();
    const randomFileName = `${uuidv4()}.${fileExtension}`;

    const params = {
      Bucket: 'connectind',
      Key: `${s3Folder}/${randomFileName}`,
      Body: file,
    };

    try {
      const upload = s3.upload(params);

      upload.on('httpUploadProgress', (event) => {
        const progressPercentage = Math.round((event.loaded / event.total) * 100);
        setProgress(progressPercentage);
      });

      await upload.promise();
      return `https://${params.Bucket}.s3.${s3.config.region}.amazonaws.com/${params.Key}`;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error; // Re-throw the error after logging it
    }
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Please select a file to upload.');
      onUploadComplete(false);
      return;
    }

    try {
      setProgress(0);
      const result = await uploadFile(selectedFile);
      setUploadStatus(result ? 'Upload successful' : 'Upload failed');
      onUploadComplete(result);
    } catch (error) {
      setUploadStatus('Upload failed due to an error.');
      onUploadComplete(false);
    }
  };

  const resetUploader = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setProgress(0); // Reset progress to 0
    setUploadStatus(null); // Clear upload status
    setSelectedFile(null); // Clear selected file
  };

  return (
    <div>
      <input type="file" ref={fileInputRef} onChange={handleFileChange} />
      <button className='btn btn-primary btn-sm' onClick={handleUpload}>Upload</button>
      {uploadStatus && <p>{uploadStatus}</p>}
      {selectedFile && (
        <div>
          {progress > 0 && (
            <div>
              <progress value={progress} max="100"></progress>
              <span>{progress}%</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
