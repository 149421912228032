import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const Myblocklist = (props) => {

  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);

  var udata = JSON.parse(user);
  var userid = udata[0].id;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
};
const data = {
}
  useEffect(() => {
    setLoader(true);
    axiosRequest.get('blocklist/user/'+userid, data, config)
      .then(function (response){
        setLoader(false);
        setData(response.data)
        console.log(response);
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, [refreshKey]);

  const usrView = (e) => {
    var result = window.confirm("Do you really want to delete to unblock this company?");
    if (result) {
    axiosRequest.get('blocklist/delete/'+e, config)
      .then(function (response){
        setLoader(false);
        setRefreshKey(oldKey => oldKey +1)
        //setERR('Successfuly uploaded Tender Document.');
        //setSuccess(err);
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
    }
  }
    return (
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Company Name</th>
                        <th>Debarment Start Date</th>
                        <th>Debarment End Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map(user =>
                        <tr key={user.id}>
                            <td>{user.blocklist_user.name}</td>
                            <td>{user.createdAt}</td>
                            <td>{user.blockedUntil}</td>
                            <td>
                            <a href="#" onClick={usrView.bind(this, user.id)}>Unblock</a>
                            </td>
                        </tr>
                    )}
                    { 
                    isLoader &&
                      <Loader></Loader>
                  }
                </tbody>
            </table>
    );
  }
  export default Myblocklist;