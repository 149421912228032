import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const Mytenders = (props) => {
  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);

  var udata = JSON.parse(user);
  var userid = udata[0].id;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
  };
  const data = {}

  const fetchData = () => {
    setLoader(true);
    axiosRequest.get('tenders/history/user/' + userid, data, config)
      .then(function (response) {
        setData(response.data)
        setLoader(false);
      })
      .catch(function (error) {
        if (error.response.data.message === 'Invalid Token') {
          navigate("/logout");
        }
      });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const tenderSearch = (event) => {
    setLoader(true);
    if (event.target.value !== '') {
      axiosRequest.get('tenders/history/user/' + userid + '?q=' + event.target.value, data, config)
        .then(function (response) {
          setData(response.data)
          setLoader(false);
        })
        .catch(function (error) {
          if (error.response.data.message === 'Invalid Token') {
            navigate("/logout");
          }
        });
    } else {
      fetchData();
    }
  }

  const usrView = (e) => {
    navigate("/tender/details/" + e);
    window.location.pathname = '/tender/details/' + e;
  }

  const usrEdt = (e) => {
    navigate("/tender/edit/" + e);
  }
  const bidsView = (e) => {
    navigate("/tenders/bids/" + e);
    window.location.pathname = '/tenders/bids/' + e;
  }

  const CorView = (e) => {
    navigate("/tenders/corrigendum/" + e);
    window.location.pathname = '/tenders/corrigendum/' + e;
  }

  const docsView = (e) => {
    navigate("/tenders/documents/" + e);
    window.location.pathname = '/tenders/documents/' + e;
  }

  const publishTender = (id) => {
    if (window.confirm("Are you sure you want to publish this tender?")) {
      axiosRequest.get('/tenders/updatestatus/' + id + '?status=2', config)
        .then(function (response) {
          if (response.data !== null && response.data.msg === 'Yes') {
            console.log("Status changed");
            fetchData(); // Reload the data after publishing the tender
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const cancelTender = (id) => {
    if (window.confirm("Are you sure you want to cancel this tender?")) {
      axiosRequest.get('/tenders/updatestatus/' + id + '?status=3', config)
        .then(function (response) {
          if (response.data !== null && response.data.msg === 'Yes') {
            console.log("Status changed");
            fetchData(); // Reload the data after canceling the tender
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const reTender = (id) => {
    if (window.confirm("Are you sure you want to retender this tender?")) {

      axiosRequest.get('/tenders/retender/' + id, config)
        .then(function (response) {
          console.log(response.data);
          if (response.data !== null && response.data.msg === 'Yes') {
            alert('Retender was successfull. Now redirecting to the page where you can change dates before making the retender live.')
            console.log(response.data);
            navigate("/tender/edit/" + response.data.newTender.id);
          }
          else
          {
            alert(response.data.msg)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const formatDate = (dateString) => {
    if (!dateString) {
      return ''; // Return blank if dateString is null, undefined, or empty
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  return (
    <table className="table table-striped table-bordered">
      <thead>
        <tr>
          <th>id</th>
          <th>title</th>
          <th>Published Date</th>
          <th>Bid Opening Date</th>
          <th>Status</th>
          <th>Action</th> {/* New column for actions */}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td><input type="text" onChange={tenderSearch} /></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        {!isLoader && users.length === 0 && (
                <tr>
                  <td colSpan="5" className="text-center">No tender found</td>
                </tr>
              )}
        {users && users.map(user =>
          <tr key={user.id}>
            <td>{user.id}</td>
            <td>
              <table style={{"width":"100%"}}>
                <tbody>
                  <tr><td colSpan={4}>{user.title}</td></tr>
                  <tr>
                    <td>
                      <a href="#" onClick={usrView.bind(this, user.id)}>View</a>
                    </td>
                  </tr>
                  <tr>
                    {user.tender_status.name === 'Draft' && (<td><a href="#" onClick={docsView.bind(this, user.id)}>Tender Documents</a></td>)}
                    {user.tender_status.name === 'Live' && (<td><a href="#" onClick={CorView.bind(this, user.id)}>Corrigendum</a></td>)}
                    {user.tender_status.name === 'Live' && (<td><a href="#" onClick={bidsView.bind(this, user.id)}>See All Bids</a></td>)}
                  </tr>
                </tbody>
              </table>
            </td>
            <td>{formatDate(user.published_date)}</td>
            <td>{formatDate(user.bid_opening_date)}</td>
            <td>{user.tender_status.name}</td>
            <td>
              {user.tender_status.name === 'Draft' ?
                <a href="#" onClick={() => publishTender(user.id)}>Publish</a> :
                user.tender_status.name === 'Live' ? 
                <a href="#" onClick={() => cancelTender(user.id)}>Cancel</a> : 
                user.tender_status.name === 'Cancelled' ? 
                <a href="#" onClick={() => reTender(user.id)}>Retender</a> : 
                null}
            </td>
          </tr>
        )}
        {
          isLoader &&
          <Loader></Loader>
        }
      </tbody>
    </table>
  );
}
export default Mytenders;
