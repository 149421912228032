import React from "react";
import { Col, Row } from "react-bootstrap";
import Bidsbytender from "../components/bids/bidsbytender";
import Sidebar from "../components/users/sidebar";
import { useNavigate } from "react-router-dom";

const Tenders = (props) => {

    const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    document.title = "All Bids Of Tender | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
            <Bidsbytender />
        </Col>
        </Row>
        </div>
    )
}

export default Tenders;