import React from "react";
import Tender from "../components/tenders";
import { Col, Row } from "react-bootstrap";
import AdminSidebar from "../components/users/adminsidebar";


const Tenders = (props) => {
    document.title = "Tender Search | ConnectIND"; 
    
    return(
        <div className='main container'>
    <Row>
    <Col md={3}>
      <AdminSidebar />
    </Col>
    <Col md={9}>
        <Tender />
    </Col>
    </Row>
</div>
    )
}

export default Tenders;