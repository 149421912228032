import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MyBids from "../components/bids/mybids";
import Sidebar from "../components/users/sidebar";

function MyBidsPage() {

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }

    document.title = "My Active Bids | ConnectIND"; 
    
  return(
    <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
        <strong class="pgheading">My Active Bids</strong>
        <br />
        <br />
            <MyBids />
        </Col>
        </Row>
        </div>
  );
}

export default MyBidsPage;