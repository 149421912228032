import React from "react";
import { Col, Row } from "react-bootstrap";
import Tendersidebar from "../components/users/frontpagesidebar";
import BidAwards from "../components/bids/bidawards";

const BidAwardsPage = (props) => {
    document.title = "Tenders by Organization | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={3} className='home'>
            <Tendersidebar />
        </Col>
        <Col md={9}>
            <BidAwards />
        </Col>
        </Row>
    </div>
    )
}

export default BidAwardsPage;