import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {Alert } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';
import FileUpload from '../App/FileUpload';
import deleteS3File from '../App/deleteS3File';

const CorrigendumbyTender = (props) => {

  const token = sessionStorage.getItem('token');
  const [corrigendums, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [tenderdata, setTender] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [isLive, setTenderStatus] = useState(false);
  const [tendercorrigendum, setTenderCor] = useState([{
    type: '',
    name: '',
    docUrl: '' // Assuming you will store the document URL here after upload
  }]);
  const [isError, setError] = useState(false);
  const [err, setERR] = useState("");
  const [isSuccess, setSuccess] = useState(false);
  const [isEditable, setEditable] = useState(true); 
  const fileUploadRef = useRef(null);


  var udata = JSON.parse(user);
  var userid = udata[0].id;
  var {id} = useParams();

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
    };
const data = {
}

const [filePaths, setFilePaths] = useState({
    doc: null,
  });

const isEmpty = (string) => {
    return string === null || string === undefined || string.trim() === "";
  };

const handleDelete = async (fileUrl) => {
    const result = await deleteS3File(fileUrl);
  };

const handleUploadComplete = (fileKey, result) => {
    if (result) {
      setFilePaths(prevPaths => ({
        ...prevPaths,
        [fileKey]: result,
      }));
    } else {
      console.log(`Upload for ${fileKey} failed.`);
    }
  };

  useEffect(() => {
    setLoader(true);
    axiosRequest.get('tenders/corrigendum/'+id, data, config)
      .then(function (response){
        setData(response.data)
        axiosRequest.get('/tenders/view/'+id, config)
          .then(function (response){
            setTender(response.data);
            const status = response.data.status;
            if (status === 2) {
              setTenderStatus(true);
            } else {
              setEditable(false);
              console.log("isEditable is false");
            }
          })
          .catch(function (error) {
            console.log(error);
          });

        setLoader(false);
      })
      .catch(function (error) {
        if(error.response.data.message ==='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, [refreshKey]);

  function handleSubmit(event) {
    setLoader(true);
    setError(false);
    setSuccess(false);
    event.preventDefault()

    if(tendercorrigendum.type!=='' && tendercorrigendum.title!=='' !=='' && filePaths.doc!=='')
    {
    const formData = new FormData();
    formData.append('type', tendercorrigendum.type);
    formData.append('title', tendercorrigendum.title);
    formData.append('doc', filePaths.doc);
    formData.append('tenderid', id);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`, 
        userid: `${userid}`
      },
    };

    console.log(formData);
    axiosRequest.post('/corrigendum/create/', formData, config)
    .then(function (response){
             if(response.status==200)
              {
                  if (response.data.msg == 'Success') {
                    setERR('Successfuly uploaded Tender corrigendum.');
                    setSuccess(err);
                    setTenderCor({
                        type: '',
                        name: '',
                        docUrl: ''
                      });
                      if (fileUploadRef.current) {
                        fileUploadRef.current.resetUploader();
                      }
                    setRefreshKey(oldKey => oldKey +1)
                  }
                  else
                  {
                    setERR("Error in Submitting your document.");
                    setError(true);
                  }
              }
              else
              {
                setERR("Error in Submitting your document.");
                setError(true);
              }
        setLoader(false);
    }).catch(function (error) {
        setERR("Error in Submitting your document.");
        setError(true);
        setLoader(false);
      });
    }
    else
    {
        setERR("Fill the form and atach a valid document.");
        setError(true);
        setLoader(false);
    }

    }

  const usrDel = (e) => {
    const userConfirmed = window.confirm('Are you sure you want to delete this Corrigendum?');
    if (userConfirmed) {
    axiosRequest.get('/corrigendum/delete/'+e, config)
          .then(function (response){
            if (response.data.msg == 'Success') {
                handleDelete(response.data.url)
            setRefreshKey(oldKey => oldKey +1)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
        }
  }
    return (
        <div className="main">
                {!isLive && (
                <Alert variant="warning">
                  You cannot upload Corrigendum as the tender status is closed.
                </Alert>
              )}

            <strong class="pgheading">Tender Details # {tenderdata.id}</strong>
            <br /><br />
            <div className="card">
              <div className="card-header">
              {tenderdata.title}
              </div>
              <div className="card-body">
                <p><strong>Bid Submission Closing Date</strong>: {tenderdata.bid_submission_closing_date}</p>
                <p><strong>Bid Opening Date</strong>:{tenderdata.bid_opening_date}</p>
              </div>
            </div>
            <br /><br />

            {!isEditable && (
              <Alert variant="warning">
                This form cannot be edited as the tender status is not live.
              </Alert>
            )}

            <div className="card">
              <div className="card-header">
              <strong class="pgheading">Upload New Corrigendum</strong>
              </div>
              <div className="card-body">
              <label>Document Type</label>
                <input 
                type="text" 
                className="form-control" 
                id="type"
                name="type"
                value={tendercorrigendum.type}
                onChange={(e) => {
                  setTenderCor({...tendercorrigendum, type: e.target.value})
                }}
                disabled={!isEditable}
                />    
                    <br />
                <label>Name</label>
                <input 
                type="text" 
                className="form-control" 
                id="title"
                name="title"
                value={tendercorrigendum.name}
                onChange={(e) => {
                  setTenderCor({...tendercorrigendum, title: e.target.value})
                }}
                disabled={!isEditable}
                />
                <br />
                <label>Select a Document:</label> 
                <FileUpload 
                        ref={fileUploadRef}
                        s3Folder="corrigendum" 
                        onUploadComplete={(result) => handleUploadComplete('doc', result)} 
                        disabled={!isEditable}
                      />
                <p></p>
                <button className="btn btn-primary" onClick={handleSubmit} disabled={!isEditable}>Create New Corrigendum</button>
              </div>
                    {
                        isSuccess &&
                             <Alert variant="success">{err}</Alert>
                     }
                     {
                      isError &&
                            <Alert variant="danger">{err}</Alert>
                     }
            </div>
            <br />
            <strong class="pgheading">Documents</strong>
            <p>All documents attached to this tender.</p>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Pubished Date</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {corrigendums && corrigendums.map(corrigendum =>
                        <tr key={corrigendum.id}>
                            <td>{corrigendum.type}</td>
                            <td>{corrigendum.title}</td>
                            <td>{corrigendum.createdAt}</td>
                            <td>
                                <a href={corrigendum.document_url}>Download</a>
                            </td>
                            <td>
                                <a href="#" onClick={usrDel.bind(this, corrigendum.id)}>Delete</a>
                            </td>
                        </tr>
                    )}
                    { 
                    isLoader &&
                        <Loader></Loader>
                    }
                </tbody>
            </table>
            </div>    
    );
  }
  export default CorrigendumbyTender;