import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { axiosRequest } from "../../api";
import { Alert } from "react-bootstrap";

const EmailVerification = () => {
  const location = useLocation();
  const [isError, setError] = useState(false);
  const [err, setERR] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    const email = new URLSearchParams(location.search).get('email');

    const verifyEmail = async () => {
      
    try {
        axiosRequest.post('/auth/verify-email', {
            email: email,
            token: token,
          })
            .then(response => {
              if (response.data.msg === 'Success') {
                setSuccess(true);
                setERR("Your account has been verified. Now you can login and start accessing your account.")
              }
            })
            .catch(function (error) {
                setError(true);
                setERR("Failed to verify email:");
            }); 

      } catch (error) {
        setError(true);
        setERR("Failed to verify email:");
      }
    };

    if (token) {
      verifyEmail();
    } else {
      console.error('Email verification token not found!');

    }
  }, []);

  return (
    <div className='main'>
      <div className="container h-100">
            {
                isSuccess &&
                     <Alert variant="success">{err}</Alert>
            }
            {
              isError &&
                    <Alert variant="danger">{err}</Alert>
            }
    </div>
    </div>
  )
};

export default EmailVerification;
