import React from "react";
import Users from "../components/users";
import { Col, Row } from "react-bootstrap";
import AdminSidebar from "../components/users/adminsidebar";

function UsersPage() {

  document.title = "Users | ConnectIND"; 
    
  return(
    
    <div className='main container'>
    <Row>
    <Col md={3}>
      <AdminSidebar />
    </Col>
    <Col md={9}>
      <Users />
    </Col>
    </Row>
</div>
  );
}

export default UsersPage;