import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Corrigendumbytender from "../components/tenders/tendercorrigendum";
import Sidebar from "../components/users/sidebar";

const Corrigendum = (props) => {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();

    if(!token) {
        navigate("/login");
        window.location.pathname = '/login';
    }
    document.title = "Tender Corrigendum | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
            <Corrigendumbytender />
        </Col>
        </Row>
        </div>
    )
}

export default Corrigendum;