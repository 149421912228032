import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const BidsHistory = (props) => {

  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);

  var udata = JSON.parse(user);
  console.log(udata[0]);
  var companyid = udata[0].company_user.id;
  var userid = 1;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
};
const data = {
}

  useEffect(() => {
    setLoader(true);
    console.log('bids/company/bidshistory/'+companyid)
    axiosRequest.get('bids/company/bidshistory/'+companyid, data, config)
      .then(function (response){
        setData(response.data)
        setLoader(false);
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, []);

  const usrView = (e) => {
    navigate("/bids/view/"+e);
    window.location.pathname = '/bids/view/'+e;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true
    });
  };
    return (
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>created Date</th>
                        <th>Bid Opening Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                  {users.length > 0 ? (
                      users.map(user => (
                        <tr key={user.id}>
                            <td>{user.cover}</td>
                            <td>{user.createdAt}</td>
                            <td>{formatDate(user.bid_tender.bid_opening_date)}</td>
                            <td>
                            <a href="#" onClick={usrView.bind(this, user.id)}>View</a>
                            </td>
                        </tr>
                  ))
                ) : (
                  !isLoader && (
                    <tr>
                      <td colSpan="4">There's nothing to show here</td>
                    </tr>
                  )
                )}
                    { 
                        isLoader &&
                             <Loader></Loader>
                    }
                </tbody>
            </table>
    );
  }
  export default BidsHistory;