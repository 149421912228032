import React from 'react';
 
const Error = () => {
   return (
      <div className='main'>
       <div class='container'>
          <h1>Error 404</h1>
            <p>Page Not Found.</p>
       </div>
      </div>
    );
}
 
export default Error;  