import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import LoginPage from '../pages/Login';
import Signup from '../pages/Signup';
import Error from '../components/Error';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Dashboard from '../pages/Dashboard';
import UserLogOut from '../pages/UserLogOut';
import TendersbyDay from "../pages/TendersbyDay";
import Profile from '../pages/Profile';
import TenderDetails from "../pages/TenderDetails";
import CorrigendumDetail from "../pages/CorrigendumDetail";
import TenderBids from "../pages/TenderBids";
import DocumentsbyTender from "../pages/DocumentsbyTender";
import CorrigendumbyTender from "../pages/CorrigendumbyTender";
import SearchResults from "../pages/SearchResults";
import BidsDetails from "../pages/BidsDetailsPage";
import EditBid from "../pages/EditBid";
import BidsViews from "../pages/BidsViewsPage";
import RoutesFactory from '../classes/configs/routesConfig';
import PostBidsPage from "../pages/PostBids";
import EditTenderForm from "../pages/EditTenderForm";
import Tendersbyorganization from "../pages/TendersByOrganization"
import ShortfallDetail from "../pages/ShortFallDetail"
import TCQueries from "../pages/TCQueryDetailsPage"
import Sfcreate from "../pages/ShortfallCreate"

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

const { getRoutes } = RoutesFactory;

function App() {
  const [routes, setRoutes] = useState(getRoutes());
  const token = sessionStorage.getItem('token');
  const [isLoggedIn, setIsLoggedIn] = useState(token ? true : false);
  const role = sessionStorage.getItem('role');

  useEffect(() => {
    let _routes = getRoutes(isLoggedIn, role);
    setRoutes(_routes)
  }, [isLoggedIn])

  return (
    <BrowserRouter>
      <Navigation
        routes={routes}
      />
      <Routes>
        {
          routes.map(route => (
            <Route exact path={route.path} element={<route.component />} />
          ))
        }
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/" element={<Home />} />
        <Route path="/tender/details/:id" element={<TenderDetails />} />
        <Route path="/corrigendum/details/:id" element={<CorrigendumDetail />} />
        <Route path="/search-results/" element={<SearchResults />} />
        <Route path="/tenders-closing/:id" element={<TendersbyDay />} />
        <Route path="/tenders/bids/:id" element={<TenderBids />} />
        <Route path="/tenders/documents/:id" element={<DocumentsbyTender />} />
        <Route path="/tenders/corrigendum/:id" element={<CorrigendumbyTender />} />
        <Route path="/bids/details/:id" element={<BidsDetails />} />
        <Route path="/bids/view/:id" element={<BidsViews />} />
        <Route path="/bids/edit/:id" element={<EditBid />} />
        <Route path="/shortfall-request/:id" element={<ShortfallDetail />} />
        <Route path="/techno-commercial-query/:id" element={<TCQueries />} />
        <Route path="/tender/:id/apply" element={<PostBidsPage />} />
        <Route path="/tender/edit/:id" element={<EditTenderForm />} />
        <Route path="/tenders-by-organization/:id" element={<Tendersbyorganization />} />
        <Route path="/shortfall-documents/:id/create" element={<Sfcreate />} />
        
        <Route exact path="*" element={<Error />}/>
        {/* <Route exact path="/" element={<Home />}/>
             <Route exact path="/about" element={<About />}/>
             <Route exact path="/login" element={<LoginPage />}/>
             <Route exact path="/signup" element={<Signup />}/>
             <Route exact path="/dashboard" element={<Dashboard />}/>
             <Route exact path="/logout" element={<UserLogOut />}/>
             <Route exact path="/contact" element={<Contact />}/> */}
        <Route element={Error} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
