import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/js/bootstrap.min.js'
import banner from '../images/connectind-banner.png'
import { size } from 'lodash';


const Navigation = (props) => {
    const { routes } = props;
    const token = sessionStorage.getItem('token');
    const role = sessionStorage.getItem('role');
    const [isAdmin, setAdmin] = useState(false);
    const [userType, setUserType] = useState(sessionStorage.getItem('type') || 'bidder');
    const today = new Date();

    const navigate = useNavigate();


  useEffect(() => {
    
    if(role == 'admin')
    {
        setAdmin(true);
    }
    sessionStorage.setItem('type', userType);
  }, [userType]);

  const handleSelect = (eventKey) => {
    if (window.confirm(`Do you want to switch to ${eventKey} role?`)) {
      setUserType(eventKey);
      sessionStorage.setItem('type', eventKey);
      window.location.reload(); // Reload the page to reflect the change
      navigate('/dashboard'); // Redirect to homepage
    }
  };
  const topbar = {
    padding: "0px"
  };
  const menu = {
    padding: "0px",
    background:"#1a4d57",
  };
  const link = {
    color: "#fff",
    fontSize: "11px",
    fontWeight: "bold",
  };

  const formattedDate = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
});

    return (
        <div>
            <div className='container logoarea' style={topbar}>
                <img src={banner} alt="ConnectIND" className='topbanner' />
            </div>    
        <Navbar expand="lg" className="bg-body-tertiary container" style={menu}>
            <Container style={menu}>
                <Navbar.Brand href="#" className='col-3'>{formattedDate}</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto navbar-right">
                        { token && !isAdmin &&
                        <Link className="nav-link" to="/homepage" style={link}>Dashboard</Link>
                        }
                        <Link className="nav-link" to="/search-results/" style={link}>Search</Link>
                        <Link className="nav-link" to="/latest-tenders/" style={link}>Active Tenders</Link>
                        <Link className="nav-link" to="/tenders-closing/today" style={link}>Tenders by Closing Date</Link>
                        <Link className="nav-link" to="/corrigendum/" style={link}>Corrigendum</Link>
                        <Link className="nav-link" to="/bid-awards/" style={link}>Bid Awards</Link>
                        <Link className="nav-link" to="/" style={link}>Home</Link>
                        { token && !isAdmin &&
                        <Link className="nav-link" to="/logout" style={link}>Logout</Link>
                        }
                    </Nav>
                    { token && !isAdmin &&
                    <Nav className="ml-auto">
                    <NavDropdown
                        title={`Switch Role (${userType})`}
                        id="basic-nav-dropdown"
                        onSelect={handleSelect}
                    >
                        <NavDropdown.Item eventKey="bidder" active={userType === 'bidder'}>Bidder</NavDropdown.Item>
                        <NavDropdown.Item eventKey="tenderPoster" active={userType === 'tenderPoster'}>Tender Poster</NavDropdown.Item>
                    </NavDropdown>
                    </Nav>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
        </div>
    );
}

export default Navigation;  