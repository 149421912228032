import React from "react";
import { Col, Row } from "react-bootstrap";
import Tendersidebar from "../components/users/frontpagesidebar";
import TenderByStatus from "../components/tenders/tenderbystatus";

const TenderByStatusPage = (props) => {
    document.title = "Tenders Status | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
            <Col md={2} className='home'>
                <Tendersidebar />
            </Col>
            <Col md={9}>
                <TenderByStatus />
            </Col>
        </Row>
    </div>
    )
}

export default TenderByStatusPage;