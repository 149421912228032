import React, { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const TendersbyDay = (props) => {
  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const [isLoader, setLoader] = useState(false);
  var heading ='';

  const navigate = useNavigate();

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };
  const data = {
  }

  var {id} = useParams();
  if(id === 'today')
  {
    heading = "Tenders Closing Today";
  }
  if(id === '7days')
  {
    heading = "Tenders Closing in 7 Days";
  }
  if(id === '15days')
  {
    heading = "Tenders Closing Within 15 Days";
  }
  document.title = heading;

  useEffect(() => {
    setLoader(true);
        axiosRequest.get('tenders/tendersclosingbyday/'+id, data, config)
        .then(function (response){
          setData(response.data)
          setLoader(false);
        })
        .catch(function (error) {
          if(error.response.data.message=='Invalid Token')
          {
              navigate("/logout");
          }
        });
    }, [id]);

  const usrView = (e) => {
    navigate("/tender/details/"+e);
    window.location.pathname = '/tender/details/'+e;
  }

  const handleChange = (e) => { 
    const { value, checked } = e.target;
    console.log(`${value} is ${checked}`);
  }; 

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const thsyle = {
    fontSize: "14px",
    fontFamily: "tahoma"

  };
  const tdsyle = {
    fontSize: "12px",
    fontFamily: "tahoma"
  };
    
    return (
            <div className="main">
                <Row className="toprow">
                <Col sm={4}>
                    <Link to="/tenders-closing/today" className="btn btn-bt" style={{float: 'right'}}>Closing Today</Link>
                  </Col>
                  <Col sm={4}>
                    <Link to="/tenders-closing/7days" className="btn btn-bt">Closing Within 7 Days</Link>
                  </Col>
                  <Col sm={4}>
                    <Link to="/tenders-closing/15days" className="btn btn-bt" style={{float: 'left'}}>Closing Within 15 Days</Link>
                  </Col>
                </Row>
                <Row>
        {isLoader && <Loader />}
      </Row>    
            <Row>
            <Col sm={12}>
              <h3 className="pgheading">{heading}</h3>
              <table className="table table-striped table-bordered">
              <thead style={thsyle}>
                      <tr>
                          <th>e-Published Date</th>
                          <th>Bid Submission Closing Date</th>
                          <th>Bids Opening On</th>
                          <th colSpan={2}>Title/Ref.No./Tender Id</th>
                          <th>Organization Name</th>
                          <th>Corrigendum</th>
                      </tr>
                  </thead>
                  <tbody style={tdsyle}>
                  {users.length > 0 ? (
                        users.map(user => (
                          <tr key={user.id}>
                            <td>{formatDate(user.published_date)}</td>
                            <td>{formatDate(user.bid_submission_closing_date)}</td>
                            <td>{formatDate(user.bid_opening_date)}</td>
                            <td colSpan={2}><a href="#" onClick={usrView.bind(this, user.id)}>{user.title}</a> / </td>
                            <td>{user.tender_company.name}</td>
                            <td>--</td>
                          </tr>
                      ))
                    ) : (
                      !isLoader && (
                        <tr>
                          <td colSpan="7">No Tender found</td>
                        </tr>
                      )
                    )}
                  </tbody>
              </table>
            </Col> 
            </Row>
        </div>
    );
  }
  export default TendersbyDay;