import React from "react";
import { Col, Row } from "react-bootstrap";
import Tendersidebar from "../components/users/frontpagesidebar"
import Tendersorganizations from "../components/tenders/tendersorganization";

const TendersOrganizationsPage = (props) => {
    document.title = "Tenders by Organization | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={2} className='home'>
          <Tendersidebar />
        </Col>
        <Col md={9}>
            <Tendersorganizations />
        </Col>
        </Row>
    </div>
    )
}

export default TendersOrganizationsPage;