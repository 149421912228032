import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";

const TCQueries = () => {
    const [shortfalls, setShortfalls] = useState([]);
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    
    if (token.exp * 1000 < Date.now()) {
        console.log('Expired');
    }
    // Retrieve the user from sessionStorage and parse it
    const user = sessionStorage.getItem('user');
    var udata = JSON.parse(user);
    var userid = udata[0].id;
    const companyId = udata[0]?.company_user?.id;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    useEffect(() => {
        // Fetch the shortfall requests based on company ID
        axiosRequest.get(`support/tcqueries/${userid}`, config)
            .then(response => {
                const data = Array.isArray(response.data) ? response.data : [response.data];
                console.log(response.data);
                setShortfalls(data);
            })
            .catch(error => {
                console.error('There was an error fetching the Techno Commercial Queries!', error);
            });
    }, [companyId]);

    // Function to handle viewing a Techno Commercial Queries
const viewtcq = (id) => {
    navigate("/techno-commercial-query/"+id);
    window.location.pathname = "/techno-commercial-query/"+id;
};

// Function to handle replying to a Techno Commercial Queries
const replyToShortfall = (id) => {
    navigate("/techno-commercial-query/"+id);
    window.location.pathname = "/techno-commercial-query/"+id;
};
const viewTender = (id) => {
    navigate("/tender/details/"+id);
    window.location.pathname = "/tender/details/"+id;
};

    return (
        <div>
            <strong class="pgheading">Active Techno Commercial Queries</strong>
            <br /><br />
            <a href="/techno-commercial-queries/create" className='float-end btn btn-primary btn-sm'>Create</a>
            <br /><br />
            {/* Display table for shortfall requests */}
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Tender Reference No</th>
                        <th>Request Description</th>
                        <th>Requested On</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {shortfalls.length > 0 ? (
                        shortfalls.map(shortfall => (
                            <tr key={shortfall.id}>
                                <td>{shortfall.id}</td>
                                <td>
                                    <a href="#" onClick={() => viewTender(shortfall.tenderid)}>{shortfall.tender?.reference_no}</a>
                                </td>
                                <td>
                                    {shortfall.request_Description 
                                        ? shortfall.request_Description.length > 30 
                                            ? `${shortfall.request_Description.substring(0, 30)}...` 
                                            : shortfall.request_Description
                                        : 'No description available'}
                                </td>
                                <td>{new Date(shortfall.requested_on).toLocaleString()}</td>
                                <td>
                                    {/* Buttons for viewing and replying to shortfall */}
                                    <button className='btn btn-primary btn-sm' onClick={() => viewtcq(shortfall.id)}>View</button>
                                    &nbsp; 
                                    <button className='btn btn-primary btn-sm' onClick={() => replyToShortfall(shortfall.id)}>Reply</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">No Techno Commercial queries found</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default TCQueries;
