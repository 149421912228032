import React, { useState, useEffect } from "react";
import { useNavigate, useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Sidebar from "../components/users/sidebar";

function Homepage() {

  const [userType, setUserType] = useState(sessionStorage.getItem('type') || 'bidder');
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  document.title = "User Home Page"; 

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
  else
  {
    const user = sessionStorage.getItem('user');
    var data = JSON.parse(user)[0];
    if(data.role === 'admin')
    {
        navigate("/dashboard");
        window.location.pathname = '/dashboard';
    }
  }
    
  return(
      <div className='main container'>
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
        <strong class="pgheading">User Panel</strong>
        <p>Hello {data.first_name} {data.last_name}. Your last login was on: {data.last_login}</p>
        </Col>
        </Row>
    </div>
  );
}

export default Homepage;