import React from "react";
import { Col, Row } from "react-bootstrap";
import WDBids from "../components/bids/withdrawnbids";
import Sidebar from "../components/users/sidebar";
import { useNavigate } from "react-router-dom";

function WithdrawnBidsPage() {

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
  
  document.title = "Withdrawd Bids | ConnectIND"; 
  
  return(
    <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
        <strong class="pgheading">Withdrawn Bids</strong>
        <br /><br />
            <WDBids />
        </Col>
        </Row>
        </div>
  );
}

export default WithdrawnBidsPage;