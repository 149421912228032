import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import EmailVerification from "../components/signup/emailverification";

function EmailVerificationPage() {
  const token = sessionStorage.getItem('token');
  
  document.title = "Login | ConnectIND"; 

  if (token) {
    window.location.pathname = '/dashboard';
  }

  return (
    <EmailVerification />
  );
}

export default EmailVerificationPage;
