import React from "react";
import { Col, Row } from "react-bootstrap";
import Tendersidebar from "../components/users/frontpagesidebar";
import CorrigendumDetails from "../components/tenders/corrigendumDetails";

function CorrigendumDetailPage() {
    
  return(
    <div className="main container">
        <Row>
        <Col md={3} className='home'>
          <Tendersidebar />
        </Col>
        <Col md={9}>
          <CorrigendumDetails />
        </Col>
        </Row>
    </div>
  );
}

export default CorrigendumDetailPage;