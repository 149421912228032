import React from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import MyDrafts from "../components/tenders/drafts";
import Sidebar from "../components/users/sidebar";

const Tenders = (props) => {

    const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    document.title = "My Tenders | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
        <strong class="pgheading">My Drafts</strong>
        <br />
            <Link className="btn btn-primary float-end" to="/tender/post">Post a New Tender</Link>
            <br /><br />
            <MyDrafts />
        </Col>
        </Row>
        </div>
    )
}

export default Tenders;