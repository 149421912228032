import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Sidebar from "../components/users/frontpagesidebar"
import { Col, Row } from "react-bootstrap";
import Signup from "../components/signup";

function SignupPage() {
  const token = sessionStorage.getItem('token');

  document.title = "Signup | ConnectIND"; 

  if(token)
  {
    window.location.pathname = '/dashboard';
  }

  return (
    <div className='main container'>
        <Row>
        <Col md={3} className='home'>
          <Sidebar />
        </Col>
        <Col md={9}>
          <Signup />
        </Col>
        </Row>
    </div>
  );
}
export default SignupPage;