import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const ShortfallRequests = () => {
    const [shortfalls, setShortfalls] = useState([]);
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    const [isLoader, setLoader] = useState(false);
    
    if (token.exp * 1000 < Date.now()) {
        console.log('Expired');
    }
    // Retrieve the user from sessionStorage and parse it
    const user = sessionStorage.getItem('user');
    var udata = JSON.parse(user);
    const companyId = udata[0]?.company_user?.id;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    useEffect(() => {
        setLoader(true);
        axiosRequest.get(`support/shortfallrequests/${companyId}`, config)
            .then(response => {
                const data = Array.isArray(response.data) ? response.data : [response.data];
                setShortfalls(data);
                setLoader(false);
            })
            .catch(error => {
                console.error('There was an error fetching the shortfall requests!', error);
            });
    }, [companyId]);

    // Function to handle viewing a shortfall request
const viewShortfall = (id) => {
    navigate("/shortfall-request/"+id);
    window.location.pathname = "/shortfall-request/"+id;
};

// Function to handle replying to a shortfall request
const replyToShortfall = (id) => {
    navigate("/shortfall-request/"+id);
    window.location.pathname = "/shortfall-request/"+id;
};

    return (
        <div>
            <strong class="pgheading">Active Shortfall Requests</strong>
            <br /><br />
            {/* Display table for shortfall requests */}
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Tender Reference No</th>
                        <th>Request Description</th>
                        <th>Requested On</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {shortfalls.length > 0 && !isLoader ? (
                        shortfalls.map(shortfall => (
                            <tr key={shortfall.id}>
                                <td>{shortfall.id}</td>
                                <td>{shortfall.bid?.bid_tender?.reference_no}</td>
                                <td>
                                    {shortfall.request_Description 
                                        ? shortfall.request_Description.length > 30 
                                            ? `${shortfall.request_Description.substring(0, 30)}...` 
                                            : shortfall.request_Description
                                        : 'No description available'}
                                </td>
                                <td>{new Date(shortfall.requested_on).toLocaleString()}</td>
                                <td>
                                    {/* Buttons for viewing and replying to shortfall */}
                                    <button className='btn btn-primary btn-sm' onClick={() => viewShortfall(shortfall.id)}>View</button>
                                    &nbsp; 
                                    <button className='btn btn-primary btn-sm' onClick={() => replyToShortfall(shortfall.id)}>Reply</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6">No shortfall requests found</td>
                        </tr>
                    )}
                    {
                    isLoader && (
                        <tr>
                        <td colSpan="6">
                            <Loader />
                        </td>
                        </tr>
                    )
                    }
                </tbody>
            </table>
        </div>
    );
};

export default ShortfallRequests;
