import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
//import { Alert } from "react-bootstrap";
import { axiosRequest } from "../../api";
import validator from 'validator'

const PasswordForm = (props) => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [formMessage, setformMessage] = useState("");
  const [password, setPassword] = useState("");
  const [npassword, setNPassword] = useState("");
  const [rpassword, setRPassword] = useState("");

  var udata = JSON.parse(user);
  
  var id = udata[0].id;
  const config = {
    headers: { Authorization: `Bearer ${token}` }
};

const loginSubmit = async (e) => {
    e.preventDefault();

    if(password!='' && npassword!='' && rpassword !='')
    {
        setformMessage('');
        if (validator.isStrongPassword(npassword, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
        })) {
            axiosRequest.post('/user/changepassword/', {
                password: password,
                npassword: npassword,
                rpassword: rpassword,
                user: id,
              }, config).then(response => {
                  console.log(response);
                  setformMessage(response.data.msg);
                  if (response.data.msg == 'Success') {
                    setformMessage(response.data.msg);
                  }
                  else
                  {
                    setformMessage(response.data.msg);
                  }
                }).catch(function (error) {
                  console.log(error);
                  setformMessage(error.response.data.msg)
                });
        } else {
            setformMessage('New Password must be 8 characters long and should include combination of small case, upper case, numbers, and symbols.')
        }
        if(npassword==rpassword)
        {

        }
        else
        {
            setformMessage('New Password and Retype password did not match.');
        }

    }
    else
    {
        setformMessage('Text fileds cannot be empty');
    }

}

  document.title = "Change Password | ConnectIND"; 

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    
    return (
            <div className="main container">
                <div className='row'>
                <div className="col-md-3">
                  <ul className="list-group">
                    <li className="list-group-item"><Link to="/editprofile" className="nav-link">Edit Profile</Link></li>
                    <li className="list-group-item"><Link to="/editcompany" className="nav-link">Edit Company</Link></li>
                    <li className="list-group-item active">Change Password</li>
                </ul>
                </div>
                <div className="col-md-9">
                <strong class="pgheading">Change Password</strong>
                <br /><br />
                <form className="mx-1 mx-md-4" onSubmit={loginSubmit}>
                <table className="profiletable">
                <tbody>
                    <tr>
                        <td>Old Password</td>
                        <td>
                            <input 
                            type="password" 
                            className="form-control"
                            id="oldp"
                            name="oldp"
                            placeholder="Enter Old Password"
                            onChange={(event) => setPassword(event.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>New Password</td>
                        <td>
                        <input 
                            type="password" 
                            className="form-control"
                            id="newp"
                            name="newp"
                            placeholder="Enter New Password"
                            onChange={(event) => setNPassword(event.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Retype New Password</td>
                        <td>
                        <input 
                            type="password" 
                            className="form-control"
                            id="rnp"
                            name="rnp"
                            placeholder="Retype New Password"
                            onChange={(event) => setRPassword(event.target.value)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td colSpan={2}>
                        <button type="submit" className="btn btn-primary">Save</button>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}><small id="fromstatus" className="text-danger form-text">{formMessage}</small></td>
                    </tr>
                </tbody>
                </table>
                </form>
            </div>
            </div>
        </div>
    );
  }
  export default PasswordForm;