import React, { useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ConfirmationModal = ({ message, showModal, onConfirm }) => {
  const [show, setShow] = useState(showModal);

  const handleClose = useCallback(() => setShow(false), []); // Avoid re-creating on every render

  const handleConfirm = useCallback(() => {
    onConfirm(true); // Pass true on confirmation
    handleClose();
  }, [onConfirm, handleClose]); // Only recreate if dependencies change

  const handleCancel = useCallback(() => {
    onConfirm(false); // Pass false on cancellation
    handleClose();
  }, [onConfirm, handleClose]); // Only recreate if dependencies change

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmationModal;