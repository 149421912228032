import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { axiosRequest } from "../../api";

const TCQueryCreate = () => {
    const [tenderRef, setTenderRef] = useState(''); // Tender Reference Number
    const [tenderDetails, setTenderDetails] = useState(null); // Stores tender details
    const [description, setDescription] = useState(''); // Description entered by the user
    const [tenderId, setTenderId] = useState(null); // Hidden tenderId
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
    
    const user = sessionStorage.getItem('user');
    var udata = JSON.parse(user);
    var userid = udata[0].id;
    const companyId = udata[0]?.company_user?.id;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    // Fetch tender details based on tender reference number
    const fetchTenderDetails = () => {
        axiosRequest.get(`/tenders/reference?ref=${tenderRef}`, config)
            .then(function (response) {
                if (response.data) {
                    console.log(response.data)
                    setTenderDetails(response.data);
                    setTenderId(response.data.id); // Store tender ID
                } else {
                    setTenderDetails(null); // No tender found
                    setTenderId(null);
                }
            })
            .catch(function (error) {
                console.error('Error fetching tender:', error);
            });
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        if (tenderId && description) {
            // Create Techno Commercial Query
            const queryData = {
                tenderId: tenderId,
                description: description,
                userId: userid
            };
            axiosRequest.post('/support/tcquery/create', queryData, config)
                .then(function (response) {
                    console.log('Query created successfully:', response.data);
                    navigate("/techno-commercial-queries/"); // Redirect after successful creation
                })
                .catch(function (error) {
                    console.error('Error creating query:', error);
                });
        } else {
            alert("Please fill in the tender reference number and description.");
        }
    };

    return (
        <div>
            <strong className="pgheading">Create A New Techno Commercial Query</strong>
            <br /><br />
            <a href="/techno-commercial-queries/" className='float-end btn btn-primary btn-sm'>Back</a>
            <br /><br />
            <div className="searchbox1">
                <form onSubmit={handleSubmit}>
                    <label>Tender Reference No</label>
                    <input 
                        type="text" 
                        className='me-2 form-control' 
                        value={tenderRef} 
                        onChange={(e) => setTenderRef(e.target.value)} 
                        onBlur={fetchTenderDetails} // Fetch tender details on input blur
                    />

                    {tenderDetails && (
                        <div>
                            <label><strong>Tender Title:</strong></label>
                            <p>{tenderDetails.title}</p>
                            <input type="hidden" value={tenderId} />
                        </div>
                    )}

                    {!tenderDetails && tenderRef && (
                        <p style={{ color: 'red' }}>No tender found with this reference number.</p>
                    )}

                    <label>Description</label>
                    <textarea 
                        className='me-2 form-control' 
                        value={description} 
                        onChange={(e) => setDescription(e.target.value)} 
                    />

                    <input type="submit" value="Submit" className="btn btn-primary btn-sm float-end" />
                    <br />
                </form>
            </div>
        </div>
    );
};

export default TCQueryCreate;