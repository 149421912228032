// awsConfig.js
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: "AKIA2KPTU5NR6FDJGXBQ",
  secretAccessKey: "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",
  region: "us-west-2",
});

const s3 = new AWS.S3();

export default s3;
