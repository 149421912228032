import React from "react";
import { Col, Row } from "react-bootstrap";
import NewTender from "../components/tenders/newtender";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/users/sidebar";

const NewTenderForm = (props) => {
    const token = sessionStorage.getItem('token');
    const navigate = useNavigate();
  
    if(!token) {
      navigate("/login");
      window.location.pathname = '/login';
    }
    
    document.title = "My Tenders | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
        <h2>Create a New Tender</h2>
            <NewTender />
        </Col>
        </Row>
        </div>
    )
}

export default NewTenderForm;