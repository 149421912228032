import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const TenderbyLocation = () => {
  const token = sessionStorage.getItem('token');
  const [users, setUsers] = useState([]); // Ensure users is initialized as an array
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [noTendersFound, setNoTendersFound] = useState(false);
  const navigate = useNavigate();

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const { id } = useParams() || 1;

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setNoTendersFound(false); // Reset noTendersFound state before making the request
    try {
      console.log(`/tenders/search?location=${searchQuery}`);
      const response = await axiosRequest.get(`/tenders/search?location=${searchQuery}`, config);
      console.log(response.data)
      const tenders = Array.isArray(response.data) ? response.data : [];
      if (tenders.length > 0) {
        setUsers(tenders);
        setNoTendersFound(false);
      } else {
        setUsers([]);
        setNoTendersFound(true);
      }
    } catch (error) {
      console.error("Error fetching tenders:", error);
      setUsers([]); // Clear previous results
      setNoTendersFound(true); // Display "No tenders found" message
    } finally {
      setLoader(false);
    }
  };

  const usrView = (e) => {
    navigate(`/tender/details/${e}`);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const thsyle = {
    fontSize: "14px",
    fontFamily: "tahoma"
  };

  const tdsyle = {
    fontSize: "12px",
    fontFamily: "tahoma"
  };

  return (
    <div className="main">
      <Row>
        <strong class="pgheading">Tenders By Location</strong>
        <div className="searchbox1">
          <form onSubmit={handleSearchSubmit}>
            <label>Please enter the city or location</label>
            <input
              type="text"
              placeholder="Type City or Pincode"
              className="me-2 form-control"
              value={searchQuery}
              onChange={handleSearchChange}
              style={{width:"300px"}}
            />
            <br />
            <input
              type="submit"
              value="Submit"
              className="btn btn-primary btn-sm float-end"
            />
          </form>
        </div>
      </Row>
      <Row>
        {isLoader && <Loader />}
      </Row>
      <br /><br />
      <Row>
        <Col sm={12}>
          <a href="/" className="float-end btn btn-primary btn-sm">Back</a>
          <br /><br />
          {noTendersFound ? (
            <div>No tenders found for the specified location.</div>
          ) : (
            <table className="table table-striped table-bordered">
              <thead style={thsyle}>
                <tr>
                  <th>e-Published Date</th>
                  <th>Bid Submission Closing Date</th>
                  <th>Bids Opening On</th>
                  <th colSpan={2}>Title/Ref.No./Tender Id</th>
                  <th>Organization Name</th>
                </tr>
              </thead>
              <tbody style={tdsyle}>
                {users.map(user => (
                  <tr key={user.id}>
                    <td>{formatDate(user.published_date)}</td>
                    <td>{formatDate(user.bid_submission_closing_date)}</td>
                    <td>{formatDate(user.bid_opening_date)}</td>
                    <td colSpan={2}>
                      <a href="#" onClick={() => usrView(user.id)}>
                        {user.title}
                      </a> / 
                    </td>
                    <td>{user.tender_company.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TenderbyLocation;
