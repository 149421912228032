import React from "react";
import { Col, Row } from "react-bootstrap";
import Tendersidebar from "../components/users/frontpagesidebar";
import TenderDetails from "../components/tenders/tenderDetailsdata";

function TenderDetailPage() {
    
  return(
    <div className="main container">
        <Row>
        <Col md={3} className='home'>
          <Tendersidebar />
        </Col>
        <Col md={9}>
          <TenderDetails />
        </Col>
        </Row>
    </div>
  );
}

export default TenderDetailPage;