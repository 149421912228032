import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";

const Search = (props) => {
  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const navigate = useNavigate();

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const data = {};

  const { id } = useParams();

  useEffect(() => {
    const start = 0;
    const limit = 10;
    axiosRequest.get(`tenders?start=${start}&limit=${limit}`, data, config)
      .then(response => {
        if (Array.isArray(response.data)) {
          setData(response.data);
        } else {
          setData([]);
        }
        console.log(response);
      })
      .catch(error => {
        if (error.response?.data?.message === 'Invalid Token') {
          navigate("/logout");
        }
      });
  }, []);

  const tenderSearch = (event) => {
    if (event.target.value !== '') {
      axiosRequest.get(`tenders/search/?q=${event.target.value}`, data, config)
        .then(response => {
          if (Array.isArray(response.data)) {
            setData(response.data);
          } else {
            setData([]);
          }
          console.log(response);
        })
        .catch(error => {
          if (error.response?.data?.message === 'Invalid Token') {
            navigate("/logout");
          }
        });
    } else {
      const start = 0;
      const limit = 10;
      axiosRequest.get(`tenders?start=${start}&limit=${limit}`, data, config)
        .then(response => {
          if (Array.isArray(response.data)) {
            setData(response.data);
          } else {
            setData([]);
          }
          console.log(response);
        })
        .catch(error => {
          if (error.response?.data?.message === 'Invalid Token') {
            navigate("/logout");
          }
        });
    }
  };

  const usrView = (e) => {
    navigate(`/tender/details/${e}`);
    window.location.pathname = `/tender/details/${e}`;
  };

  return (
    <div className="main">
      <div className="container">
        <h2 className="p-3">All Tenders</h2>
        <table className="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Title</th>
              <th>City</th>
              <th>Tender Value</th>
              <th>Published Date</th>
              <th>Bid Opening Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><input type="text" onChange={tenderSearch} /></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            {Array.isArray(users) && users.map(user => (
              <tr key={user.id}>
                <td>{user.title}</td>
                <td>{user.city}</td>
                <td>{user.tender_value}</td>
                <td>{user.published_date}</td>
                <td>{user.bid_opening_date}</td>
                <td>
                  <a href="#" onClick={() => usrView(user.id)}>View</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Search;
