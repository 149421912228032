import React from "react";
import { Col, Row } from "react-bootstrap";
import Tendersidebar from "../components/users/frontpagesidebar";
import Tendersbylocation from "../components/tenders/tendersbylocation";

const TendersbyLocation = (props) => {
    document.title = "Tenders by Location | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={2} className='home'>
            <Tendersidebar />
        </Col>
        <Col md={9}>
            <Tendersbylocation />
        </Col>
        </Row>
    </div>
    )
}

export default TendersbyLocation;