import React from "react";
import { Col, Row } from "react-bootstrap";
import Sidebar from "../components/users/sidebar";
import Profile from "../components/users/userProfile";

function ProfilePage() {
    
  return(
    <div className='main container'>
      <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
          <Profile />
        </Col>
        </Row>
    </div>
  );
}

export default ProfilePage;