import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosRequest } from "../../api";

const Users = (props) => {

  const token = sessionStorage.getItem('token');
  const [users, setUsers] = useState([]);
  
  const navigate = useNavigate();

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}` }
};
const data = {
}

  useEffect(() => {
    axiosRequest.get('/user', data, config)
      .then(function (response){
        setUsers(response.data)
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, []);

  const usrDelete = (e) => {
    if (window.confirm("Do you really want to delete the record?")) {
        alert('You are Deleting record: '+e);
      }
  }

  const usrEdit = (e) => {
    alert('You are Editing record: '+e);
  }

  const usrView = (e) => {
    navigate("/profile/"+e);
    window.location.pathname = '/profile/'+e;
  }
    
    return (
            <div className="main">
                <div className='container'>
            <h2 className="p-3">Users</h2>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Last Login</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map(user =>
                        <tr key={user.id}>
                            <td>{user.id}</td>
                            <td>{user.first_name} {user.last_name}</td>
                            <td>{user.email}</td>
                            <td>{user.phone}</td>
                            <td>{user.last_login}</td>
                            <td>
                            <a href="#" onClick={usrView.bind(this, user.id)}>View</a> &nbsp; <a href="#" onClick={usrEdit.bind(this, user.id)}>Edit</a> &nbsp; <a href="#" onClick={usrDelete.bind(this, user.id)}>Delete</a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            
            
            </div>
        </div>
    );
  }
  export default Users;