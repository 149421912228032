import React from "react";
import { useNavigate } from "react-router-dom";
import AdminSidebar from "../components/users/adminsidebar";
import { Col, Container, Row } from "react-bootstrap";

function Dashboard() {
  const token = sessionStorage.getItem('token');
  const user = sessionStorage.getItem('user');
  var data = JSON.parse(user)[0];
  console.log(data);
  const navigate = useNavigate();

  document.title = "Dashboard Page"; 

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
  else
  {
    const user = sessionStorage.getItem('user');
    var data1 = JSON.parse(user)[0];
    if(data1.role === 'user')
    {
        navigate("/homepage");
        window.location.pathname = '/homepage';
    }
  }
    
  return(
    <div className='main container'>
    <Row>
    <Col md={3}>
      <AdminSidebar />
    </Col>
    <Col md={9}>
    <h2>Dashboard</h2>
        <p>Hello {data.first_name} {data.last_name}</p>
    </Col>
    </Row>
</div>
  );
}

export default Dashboard;