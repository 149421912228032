import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const BidAwards = () => {
  const token = sessionStorage.getItem('token');
  const [users, setUsers] = useState([]); // Ensure users is initialized as an array
  const [tenderid, setTenderId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [noTendersFound, setNoTendersFound] = useState(false);
  const navigate = useNavigate();

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const { id } = useParams() || 1;

  const handleSearchChange = (e) => {
    setTenderId(e.target.value);
  };

  const handleSearchChange1 = (e) => {
    setKeyword(e.target.value);
  };

  useEffect(() => {
    setLoader(true);
    setNoTendersFound(false); // Reset noTendersFound state before making the request
    try {
      axiosRequest.get(`/bids/awardedbids`, config)
      .then(function (response){
        console.log(response.data)
        const tenders = Array.isArray(response.data) ? response.data : [];
        if (tenders.length > 0) {
          setUsers(tenders);
          setNoTendersFound(false);
        } else {
          setUsers([]);
          setNoTendersFound(true);
        }
        setLoader(false);
      })
      .catch(function (error) {
      });
    } catch (error) {
      console.error("Error fetching tenders:", error);
      setUsers([]); // Clear previous results
      setNoTendersFound(true); // Display "No tenders found" message
    } finally {
      setLoader(false);
    }

  }, []);

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setNoTendersFound(false); // Reset noTendersFound state before making the request
    try {
      console.log(`/bids/awardedbids/search?ref=${tenderid}&query=${keyword}`);
      const response = await axiosRequest.get(`/bids/awardedbids/search?ref=${tenderid}&query=${keyword}`, config);
      console.log(response.data);
      const tenders = Array.isArray(response.data) ? response.data : [];
      if (tenders.length > 0) {
        setUsers(tenders);
        setNoTendersFound(false);
      } else {
        setUsers([]);
        setNoTendersFound(true);
      }
    } catch (error) {
      console.error("Error fetching tenders:", error);
      setUsers([]); // Clear previous results
      setNoTendersFound(true); // Display "No tenders found" message
    } finally {
      setLoader(false);
    }
  };

  const usrView = (e) => {
    navigate(`/tender/details/${e}`);
  };

  const cView = (e) => {
    navigate(`/tender/corrigendum/${e}`);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const thsyle = {
    fontSize: "12px",
    fontFamily: "tahoma"
  };

  const tdsyle = {
    fontSize: "12px",
    fontFamily: "tahoma"
  };

  return (
    <div className="main">
      <Row>
        <strong class="pgheading">Results Of Tenders</strong>
        <div className="searchbox1">
          <label class="pgheading">Search</label>
          <form onSubmit={handleSearchSubmit}>
            <table>
                <tr>
                    <td><label>Tender ID</label></td>
                    <td>
                        <input
                        type="text"
                        placeholder="Tender Id"
                        className="me-2 form-control"
                        value={tenderid}
                        onChange={handleSearchChange}
                        style={{width:"300px"}}
                        />
                    </td>
                </tr>
                <tr>
                    <td><label>Keyword</label></td>
                    <td>
                        <input
                        type="text"
                        placeholder="Keyword"
                        className="me-2 form-control"
                        value={keyword}
                        onChange={handleSearchChange1}
                        style={{width:"300px"}}
                        />
                    </td>
                </tr>
            </table>
            <br />
            <input
              type="submit"
              value="Submit"
              className="btn btn-primary btn-sm float-end"
            />
          </form>
        </div>
      </Row>
      <Row>
        {isLoader && <Loader />}
      </Row>
      <br /><br />
      <Row>
        <Col sm={12}>
          <a href="/" className="float-end btn btn-primary btn-sm">Back</a>
          <br /><br />
          {noTendersFound ? (
            <div>No tenders found.</div>
          ) : (
            <table className="table table-striped table-bordered">
              <thead style={thsyle}>
                <tr>
                  <th>AOC Date</th>
                  <th>e-Published Date</th>
                  <th colSpan={2}>Title/Ref.No./Tender Id</th>
                  <th>Organization Name</th>
                  <th>AOC</th>
                </tr>
              </thead>
              <tbody style={tdsyle}>
                {users.map(user => (
                  <tr key={user.id}>
                    <td>{formatDate(user.createdAt)}</td>
                    <td>{formatDate(user.createdAt)}</td>
                    <td colSpan={2}>
                      <a href="#" onClick={() => usrView(user.tender.id)}>
                        {user.tender.title}
                      </a> / {user.tender.reference_no}
                    </td>
                    <td>{user.tender.tender_company.name}</td>
                    <td colSpan={2}>
                      <a href={user.aoc} target="_blank">
                        Download
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BidAwards;
