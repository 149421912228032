import React from "react";
import { Col, Row } from "react-bootstrap";
import TenderSearch from "../components/tenders/search";

const SearchResults = (props) => {
    document.title = "Tender Search | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
        <Col md={12}>
            <TenderSearch />
        </Col>
        </Row>
    </div>
    )
}

export default SearchResults;