import React from "react";
import { Col, Row } from "react-bootstrap";
import Tendersidebar from "../components/users/tendersidebar";
import LatestTender from "../components/tenders/latestactivetenders";

const Tenders = (props) => {
    document.title = "Latest Tenders | ConnectIND"; 
    
    return(
        <div className="main container">
        <Row>
            <LatestTender />
        </Row>
    </div>
    )
}

export default Tenders;