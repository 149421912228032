import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {Alert } from "react-bootstrap";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const DocumentsbyTender = (props) => {

  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [tenderdata, setTender] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const [types, setDocTypes] = useState([]);
  //const [typeValue, setTypeValue] = useState('');
  const [tenderdocuments, setTenderDoc] = useState([]);
  const [document, setDocument] = useState();
  const [documentName, setDocumentName] = useState("");
  const [isError, setError] = useState(false);
  const [err, setERR] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  var udata = JSON.parse(user);
  var userid = udata[0].id;
  var {id} = useParams();

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
    };
const data = {
}
const saveDocuments = (e) => {
    setDocument(e.target.files[0]);
    setDocumentName(e.target.files[0].name);
  };

  useEffect(() => {
    setLoader(true);
    axiosRequest.get('tenders/documents/'+id, data, config)
      .then(function (response){
        setData(response.data)

        axiosRequest.get('/tenders/view/'+id, config)
          .then(function (response){
            setTender(response.data);
          })
          .catch(function (error) {
            console.log(error);
          });

          axiosRequest.get('tenders/documents/types', data, config)
          .then(function (response){
            setDocTypes(response.data)
          })

        setLoader(false);
      })
      .catch(function (error) {
        if(error.response.data.message ==='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, [refreshKey]);

  function handleSubmit(event) {
    setLoader(true);
    setError(false);
    setSuccess(false);
    event.preventDefault()
    console.log(tenderdocuments);

    if(tenderdocuments.type!=='' && tenderdocuments.name!=='' && tenderdocuments.description!=='' && documentName!=='')
    {
    const formData = new FormData();
    formData.append('file', document);
    formData.append('fileName', documentName);
    formData.append('type', tenderdocuments.type);
    formData.append('name', tenderdocuments.name);
    formData.append('description', tenderdocuments.description);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`, 
        userid: `${userid}`
      },
    };

    axiosRequest.post('/tenders/documents/create/'+id, formData, config)
    .then(function (response){
        console.log(response);
             if(response.status==200)
              {
                  if (response.data.msg == 'Success') {
                    setRefreshKey(oldKey => oldKey +1)
                    setERR('Successfuly uploaded Tender Document.');
                    setSuccess(err);

                    setTenderDoc({
                      type: '',
                      name: '',
                      description: ''
                  });
                  setDocument(null);
                  setDocumentName('');
                  window.location.reload()

                  }
                  else
                  {
                    setERR("Error in Submitting your document.");
                    setError(true);
                  }
              }
              else
              {
                setERR("Error in Submitting your document.");
                setError(true);
              }
        setLoader(false);
    }).catch(function (error) {
        setERR("Error in Submitting your document.");
        setError(true);
        setLoader(false);
      });
    }
    else
    {
        setERR("Fill the form and atach a valid document.");
        setError(true);
        setLoader(false);
    }

    }

  const usrDel = (e) => {

    axiosRequest.get('/tenders/documents/delete/'+e, config)
          .then(function (response){
            setRefreshKey(oldKey => oldKey +1)
          })
          .catch(function (error) {
            console.log(error);
          });
  }
    return (
        <div className="main">
            <strong class="pgheading">Tender Details # {tenderdata.id}</strong>
            <a href="/livetenders" className="btn btn-primary btn-small float-end">Back</a>
            <br /><br />
            <div className="card">
              <div className="card-header">
              {tenderdata.title}
              </div>
              <div className="card-body">
                <p><strong>Bid Submission Closing Date</strong>: {tenderdata.bid_submission_closing_date}</p>
                <p><strong>Bid Opening Date</strong>:{tenderdata.bid_opening_date}</p>
              </div>
            </div>
            <br /><br />
            <div className="card">
              <div className="card-header">
                <strong class="pgheading">Upload a new document</strong>
              </div>
              <form onSubmit={handleSubmit}> 
              <div className="card-body">
              <label>Document Type</label>
                <select className="form-control selectpicker" name="state" onChange={(e) => { setTenderDoc({...tenderdocuments, type: e.target.value}) }} value={tenderdocuments.type || ""}>
                    <option value="">- Select Type -</option>
                    { types && types.map(state =>                      
                      <option value={state.id}> {state.name}</option>
                    )}
                    </select>
                    <br />
                <label>Name</label>
                <input 
                type="text" 
                className="form-control" 
                id="name"
                name="name"
                value={tenderdocuments.name}
                onChange={(e) => {
                  setTenderDoc({...tenderdocuments, name: e.target.value})
                }}
                />
                <br />
                <label>Description</label>
                <textarea 
                className="form-control"
                id="description"
                name="description"
                value={tenderdocuments.description}
                onChange={(e) => {
                  setTenderDoc({...tenderdocuments, description: e.target.value})
                }}
                >
                </textarea>
                <br />
                <label>Select a Document:</label> <input type="file" accept=".pdf" onChange={saveDocuments} />
                <p></p>
                <button className="btn btn-primary">Upload Document</button>
              </div>
                    {
                        isSuccess &&
                             <Alert variant="success">{err}</Alert>
                     }
                     {
                      isError &&
                            <Alert variant="danger">{err}</Alert>
                     }
              </form>
            </div>
            <br />
            <h3>Documents</h3>
            <p>All documents attached to this tender.</p>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Title</th>
                        <th>Pubished Date</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {users && users.map(user =>
                        <tr key={user.id}>
                            <td>{user.tender_documents_type.name}</td>
                            <td>{user.name}</td>
                            <td>{user.description}</td>
                            <td>
                                <a href="#">Download</a>
                            </td>
                            <td>
                                <a href="#" onClick={usrDel.bind(this, user.id)}>Delete</a>
                            </td>
                        </tr>
                    )}
                    { 
                    isLoader &&
                        <Loader></Loader>
                    }
                </tbody>
            </table>
            </div>    
    );
  }
  export default DocumentsbyTender;