import React from "react";
import { Col, Row } from "react-bootstrap";
import ExpBids from "../components/bids/expiredbids";
import Sidebar from "../components/users/sidebar";
import { useNavigate } from "react-router-dom";

function ExpiredBidsPage() {

  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }

    document.title = "Expired Bids | ConnectIND"; 
    
  return(
    <div className="main container">
        <Row>
        <Col md={3}>
          <Sidebar />
        </Col>
        <Col md={9}>
        <strong class="pgheading">Expired Bids</strong>
        <br /><br />
            <ExpBids />
        </Col>
        </Row>
        </div>
  );
}

export default ExpiredBidsPage;