import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { axiosRequest } from "../../api";
import Loader from '../App/loader';

const MyWDbids = (props) => {

  const token = sessionStorage.getItem('token');
  const [users, setData] = useState([]);
  const user = sessionStorage.getItem('user');
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);

  var udata = JSON.parse(user);
  console.log(udata[0]);
  var companyid = udata[0].company_user.id;
  var userid = udata[0].id;

  if (token.exp * 1000 < Date.now()) {
    console.log('Expired');
  }

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${userid}` }
};
const data = {
}

console.log('bids/company/withdrawn/'+companyid);

  useEffect(() => {
    setLoader(true);
    axiosRequest.get('bids/company/withdrawn/'+companyid, data, config)
      .then(function (response){
        setData(response.data)
        setLoader(false);
      })
      .catch(function (error) {
        if(error.response.data.message=='Invalid Token')
        {
            navigate("/logout");
        }
      });
  }, []);

  const usrView = (e) => {
    navigate("/bids/view/"+e);
    window.location.pathname = '/bids/view/'+e;
  }
    return (
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>title</th>
                        <th>Withdraw Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                  {users.length > 0 ? (
                      users.map(user => (
                        <tr key={user.id}>
                            <td>{user.cover}</td>
                            <td>{user.withdrawal_date}</td>
                            <td>
                            <a href="#" onClick={usrView.bind(this, user.id)}>View</a>
                            </td>
                        </tr>
                   ))
                  ) : (
                    !isLoader && (
                      <tr>
                        <td colSpan="3">No Bids found</td>
                      </tr>
                    )
                  )}
                    {
                  isLoader && (
                    <tr>
                      <td colSpan="6">
                        <Loader />
                      </td>
                    </tr>
                  )
                }
                </tbody>
            </table>
    );
  }
  export default MyWDbids;