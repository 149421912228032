import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { axiosRequest } from "../../api";
import { LoginHelpers } from "../../classes/helpers/loginHelpers";

const Password = (props) => {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [passwordError, setpasswordError] = useState("");
    const [emailError, setemailError] = useState("");
    const [formMessage, setformMessage] = useState("");

    const { validate } = LoginHelpers;

    const loginSubmit = async (e) => {
        e.preventDefault();

        if(email!=='')
        {
            axiosRequest.post('/auth/passwordreset', {
            email: email,
            })
            .then(response => {
                if (response.data.msg == 'Success') {
                    setformMessage("An Email has been sent to your registered Email Address");
                    e.target.reset();
                }
                else
                {
                    setformMessage("There was an error.");
                }
            })
            .catch(function (error) {
                console.log(error);
                setformMessage(error.response.data.msg)
              });        
        }

    };

    return (
                    <div>
                        <strong class="pgheading">Password Reset</strong>
                                <div className="row justify-content-center">
                                    <div className="col-md-10 col-lg-20 col-xl-6 order-2 order-lg-1">
                                        <form className="mx-1 mx-md-4" onSubmit={loginSubmit}>
                                            <small id="fromstatus" className="text-danger form-text">{formMessage}</small>
                                            <div className="d-flex flex-row align-items-center mb-4">
                                                <div className="form-outline flex-fill mb-0">
                                                    <label className="form-label" htmlFor="form3Example3c">Your Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        id="EmailInput"
                                                        name="EmailInput"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter email address"
                                                        onChange={(event) => setEmail(event.target.value)}
                                                    />
                                                    <small id="emailHelp" className="text-danger form-text">
                                                        {emailError}
                                                    </small>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4">
                                                <button type="submit" className="btn btn-primary">Reset</button>
                                            </div>
                                            <p className="text-center text-muted mt-5 mb-0">Don't have an account? <Link to="/signup">Sign Up</Link></p>
                                            <p className="text-center text-muted mb-0">Already have an account? <Link to="/login">Login In</Link></p>
                                        </form>

                                    </div>
                                </div>
                            </div>
    )
}

export default Password;