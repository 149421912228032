import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Loader from '../App/loader';
import { axiosRequest } from "../../api";
import { Alert } from "react-bootstrap";

const EditProfileForm = (props) => {
  const token = sessionStorage.getItem('token');
  const navigate = useNavigate();
  const user = sessionStorage.getItem('user');
  const [isLoader, setLoader] = useState(false);
  const [data, setUser] = useState([]);
  const [formMessage, setformMessage] = useState("");
  const [isError, setError] = useState(false);
  const [err, setERR] = useState("");
  const [isSuccess, setSuccess] = useState(false);

  var udata = JSON.parse(user);
  var id = udata[0].id;

  const config = {
    headers: { Authorization: `Bearer ${token}`, userid: `${id}` }
};
const userdata = {id}
useEffect(() => {
  setLoader(true);
  if(id)
  {
    axiosRequest.get('/user/view/'+id, userdata, config)
      .then(function (response){
        setUser(response.data);
        setLoader(false);
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}, [])

const formSubmit = async (e) => {
  e.preventDefault();
  setLoader(true);

   axiosRequest.post('/user/update/'+data.id, data, config)
   .then(function (response){
    setLoader(false);
    console.log(response);
     if(response.status==200)
      {
          if (response.data.msg == 'Success') {
              setSuccess(true);
              setERR("Changes have been made successfully.")
          }
          else
          {
            setError(true);
            setERR("Error in updating the form data.");
          }
      }
      else
      {
          setError(true);
          setERR("You cannot edit this company.");
      }

    })
    .catch(function (error) {
      console.log(error);
    });
}

  document.title = "Edit Profile | ConnectIND"; 

  if(!token) {
    navigate("/login");
    window.location.pathname = '/login';
  }
    
    return (
            <div className="main container">
                <div className='row'>
                <div className="col-md-3">
                  <ul className="list-group">
                    <li className="list-group-item active">Edit Profile</li>
                    <li className="list-group-item"><Link to="/editcompany" className="nav-link">Edit Company</Link></li>
                    <li className="list-group-item"><Link to="/changepass" className="nav-link">Change Password</Link></li>
                </ul>
                </div>
                <div className="col-md-9">
                <strong class="pgheading">Edit Profile Page</strong>
                <br /><br />
                <p>You cannot change your phone number and email address.</p>
                <hr />
                <form onSubmit={formSubmit}>
                <table className="profiletable">
                <tbody>
                    <tr>
                        <td>First Name</td>
                        <td>
                            <input 
                            type="text" 
                            className="form-control"
                            id="fname"
                            name="fname"
                            value={data.first_name}
                            onChange={(e) => {
                              setUser({...data, first_name: e.target.value})
                          }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Last Name</td>
                        <td>
                        <input 
                            type="text" 
                            className="form-control"
                            id="lname"
                            name="lname"
                            value={data.last_name}
                            onChange={(e) => {
                              setUser({...data, last_name: e.target.value})
                          }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>
                        <input 
                            type="email" 
                            className="form-control"
                            id="email"
                            name="email"
                            value={data.email}
                            readOnly={true} 
                            onChange={(e) => {
                              setUser({...data, email: e.target.value})
                          }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Phone Number</td>
                        <td>
                            <input 
                            type="text" 
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={data.phone}
                            readOnly={true} 
                            onChange={(e) => {
                              setUser({...data, phone: e.target.value})
                          }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Date Of Birth</td>
                        <td>
                            <input 
                            type="date"
                            className="form-control"
                            id="dob"
                            name="dob"
                            value={data.dob}
                            onChange={(e) => {
                              setUser({...data, dob: e.target.value})
                          }}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td></td>
                        <td colSpan={2}>
                            <button type="submit" className="btn btn-primary">Save</button>
                        </td>
                    </tr>
                    <tr><td colSpan={2}>
                    { 
                        isLoader &&
                             <Loader></Loader>
                    }                   
                    </td></tr>
                    <tr>
                    <td colSpan={2}> 
                    {
                        isSuccess &&
                            <Alert variant="success">{err}</Alert>
                    }
                    {
                      isError &&
                            <Alert variant="danger">{err}</Alert>
                    }
                    </td>
                    </tr>
                </tbody>
                </table>
                </form>
            </div>
            </div>
        </div>
    );
  }
  export default EditProfileForm;